import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
import { ConvertResponseForSelect } from '../../components/helper/commonfunctions';
import { FinancialYear, Inquiry, InquiryStatus, InquirySource, InquiryServices } from '../../models';
import Freight_Forwarders1 from '../../assets/images/Freight_Forwarders1.png';
import Freight_Forwarders2 from '../../assets/images/Freight_Forwarders2.png';
import Freight_Forwarders3 from '../../assets/images/Freight_Forwarders3.png';
import Freight_Forwarders4 from '../../assets/images/Freight_Forwarders4.png';
import Freight_Forwarders5 from '../../assets/images/Freight_Forwarders5.png';
import Freight_Forwarderslg from '../../assets/images/Freight-Forwarder-logo.png';
import PremiumBadge from '../../assets/images/premium-badge.svg';
import CloseBtn from '../../assets/svg/close_btn.svg';
import Select from 'react-select';
import ReactEChart from "echarts-for-react";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import moment from 'moment';


export default function Dashboard() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isShowCreatePopUp, setShowCreatePopUp] = useState(0);

    //Filter Data
    const [fromdateOfEnquiry, setFromDateOfEnquiry] = useState();
    const [todateOfEnquiry, setToDateOfEnquiry] = useState("");
    const [totalInquiry, setTotalInquiry] = useState("");
    const [inquiryList, setInquiryList] = useState([]);

    //Total Inquirys
    const [totalInquiryCount, setTotalInquiryCount] = useState('');
    const [openInquiryCount, setOpenInquiryCount] = useState('');
    const [closedInquiryResponse, setClosedInquiryResponse] = useState('');

    //Financial Year set
    const [financialYear, setFinancialYear] = useState("");
    const [financialYears, setfinancialYears] = useState([]);
    const [financialYearId, setFinancialYearId] = useState("");


    //Service Set
    const [services, setServices] = useState("");
    const [service, setService] = useState([]);
    const [serviceId, setServiceId] = useState("");




    const getFilter = async () => {
        var financialYearDetails = await DataStore.query(FinancialYear, financialYearId);
        //console.log("YEAR", financialYearId);
        if (financialYearId) {
            var fromDate = moment(financialYearDetails.fromDate).format();
            var toDate = moment(financialYearDetails.toDate).format();
            var inquiryResponse = await DataStore.query(Inquiry,
                (c) => c.and(c => [
                    c.inquiryDate.ge(fromDate),
                    c.inquiryDate.le(toDate),
                    c.inquiryServiceId.contains(serviceId),
                ]),
            );

            var openInquiryResponse = await DataStore.query(Inquiry,
                (c) => c.and(c => [
                    c.inquiryDate.ge(fromDate),
                    c.inquiryDate.le(toDate),
                    c.inquiryServiceId.contains(serviceId),
                    c.inquiryStatus.eq(InquiryStatus.OPEN),
                ]),
            );

            var closedInquiryResponse = await DataStore.query(Inquiry,
                (c) => c.and(c => [
                    c.inquiryDate.ge(fromDate),
                    c.inquiryDate.le(toDate),
                    c.inquiryServiceId.contains(serviceId),
                    c.inquiryStatus.eq(InquiryStatus.CLOSED),
                ]),
            );

        } else {
            var inquiryResponse = await DataStore.query(Inquiry,
                (c) => c.and(c => [
                    c.inquiryServiceId.contains(serviceId),
                ]),
            );

            var openInquiryResponse = await DataStore.query(Inquiry,
                (c) => c.and(c => [
                    c.inquiryServiceId.contains(serviceId),
                    c.inquiryStatus.eq(InquiryStatus.OPEN),
                ]),
            );

            var closedInquiryResponse = await DataStore.query(Inquiry,
                (c) => c.and(c => [
                    c.inquiryServiceId.contains(serviceId),
                    c.inquiryStatus.eq(InquiryStatus.CLOSED),
                ]),
            );
        }
        //console.log("Count",inquiryResponse.length)
        setTotalInquiryCount(inquiryResponse.length)
        setOpenInquiryCount(openInquiryResponse.length)
        setClosedInquiryResponse(closedInquiryResponse.length)

        let inquiryFinalData = []
        let currentServiceName = ""

        for (let i = 0; i < inquiryResponse.length; i++) {

            //set Inquiry Services 
            if (inquiryResponse[i].inquiryServiceId) {
                var currentService = await DataStore.query(InquiryServices, inquiryResponse[i].inquiryServiceId);

                if (currentService)
                    currentServiceName = currentService.name
                else
                    currentServiceName = ""

            } else { currentServiceName = "" }


            let data = {
                "InquiryID": inquiryResponse[i].id,
                "InquiryNo": inquiryResponse[i].inquiryNo,
                "InquiryDate": inquiryResponse[i].inquiryDate === "1970-01-01T12:30:23.999Z" ? "" : moment(inquiryResponse[i].inquiryDate).format('MM/DD/YYYY'),
                "Service": currentServiceName,
                "Inquirysource": InquirySource.WEBSITE,
                "Cargo Ready Date": inquiryResponse[i].inquiryCargoReadyDate === "1970-01-01Z" ? "" : moment(inquiryResponse[i].inquiryCargoReadyDate).format('MM/DD/YYYY'),
                "Contract Duration": "1 month",
                "Inquiry Closing Date": inquiryResponse[i].inquiryClosingDate === "1970-01-01Z" ? "" : moment(inquiryResponse[i].inquiryClosingDate).format('MM/DD/YYYY'),
                "Bids Invited": "12/25/22",
                "Bids Received": "12/25/22",
                "Quote Submitted Date": "12/25/22",
                "Customer Response Date": "12/25/22",
                "Status": inquiryResponse[i].inquiryStatus,
                "Inquiry Cycle Time": "5 days"
            }
            inquiryFinalData.push(data);
        }
        setInquiryList(inquiryFinalData);
    }

    const trendAnalysisFiveYearsOption = {

        color: ["#029046", "#80C7A2", "#479F4C"],
        dataset: {
            source: [
                ["type", "2018-19", "2019-20", "2020-21", "2021-22", "2022-23"],
                ["Inquires", 800, 900, 980, 1000, 1180],
                ["Quotation Accepted", 600, 610, 700, 700, 800],
                ["Inquiry -Lost", 300, 300, 300, 350, 400],
            ]
        },
        grid: {
            show: false,
            top: 10,
            right: 10,
            left: "13%",
            bottom: "35%"
        },
        legend: {
            show: true,
            bottom: 45
        },
        xAxis: {
            name: "Financial Year",
            nameLocation: "middle",
            nameGap: 26,
            type: "category",
            axisTick: {
                show: false
            }
        },
        yAxis: {
            name: "Inquires and Quotation Executed",
            nameLocation: "middle",
            nameGap: 35
        },
        series: [{
            type: "bar",
            barGap: 0,
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        }]
    };
    const monthlyTrendCurrentYearOption = {

        color: ["#7690EF", "#FFA600", "#FFCE72"],
        dataset: {
            source: [
                ["type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                ["Inquires", 90, 70, 80, 70, 82, 70, 80, 60, 85, 75, 63, 60],
                ["Quotation Accepted", 80, 60, 70, 60, 72, 60, 70, 50, 75, 65, 53, 50],
                ["Inquiry -Lost", 70, 50, 60, 50, 62, 50, 60, 40, 65, 55, 43, 40],
            ]
        },
        grid: {
            show: false,
            top: 10,
            right: 10,
            left: "13%",
            bottom: "35%"
        },
        legend: {
            show: true,
            bottom: 45
        },
        xAxis: {
            name: "Month",
            nameLocation: "middle",
            nameGap: 26,
            type: "category",
            axisTick: {
                show: false
            }
        },
        yAxis: {
            min: 15,
            interval: 15,
            max: 105,
            name: "Inquires and Quotation Executed",
            nameLocation: "middle",
            nameGap: 35
        },
        series: [{
            type: "bar",
            barGap: 0,
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        }]
    };
    const averageInquiryLifecycleTrendCurrentYearOption = {
        color: ['#FFAB01'],
        grid: {
            top: 35
        },
        xAxis: {
            name: "Month",
            nameLocation: "middle",
            nameGap: 26,
            type: 'category',
            axisTick: {
                show: false
            },
            data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        },
        yAxis: {
            name: "Avg. Inquiry Lifecycle (days)",
            nameLocation: "middle",
            nameGap: 26,
            type: 'value',
            min: 2,
            max: 14,
            interval: 2
        },
        series: [
            {
                data: [6, 4, 5, 8, 7, 6, 8, 8, 11, 12, 10, 12],
                type: 'line'
            }
        ]
    };
    const TopReasonsForQuotationDeclineByCustomersOption = {
        color: ['#01409E'],
        grid: {
            top: 35,
            left: 55
        },
        xAxis: {
            name: "Month",
            nameLocation: "middle",
            nameGap: 26,
            type: 'value',
            axisLine: {
                show: true
            },
            min: 0,
            interval: 5,
            max: 50,
            axisLabel: {
                formatter: '{value}%'
            }
        },
        yAxis: {
            data: ["Others", "Time", "FF", "Services", "Price"],
            type: 'category',
            axisTick: {
                show: false
            },
        },
        series: [
            {
                data: [3, 4, 8, 10, 30],
                type: 'bar',
                label: {
                    show: true,
                    position: 'right',
                    formatter: function (d) {
                        return d.data + "%";
                    },
                    valueAnimation: true
                }
            }
        ]
    };

    //  pop-up charts
    const trendAnalysisFiveYearsPopUpOption = {

        color: ["#256D85", "#7690EF", "#FFA600", "#FFCE72"],
        dataset: {
            source: [
                ["type", "2018-19", "2019-20", "2020-21", "2021-22", "2022-23"],
                ["Bids Invited", 1100, 1100, 1100, 1100, 1100],
                ["Responded", 1000, 1000, 1000, 1000, 1000],
                ["Confirmed Bidder", 700, 700, 700, 700, 700],
                ["Executed", 800, 800, 800, 800, 800],
            ]
        },
        grid: {
            show: false,
            top: 7,
            right: 10,
            left: "16%",
            bottom: "38%"
        },
        legend: {
            show: true,
            bottom: 30
        },
        xAxis: {
            name: "Financial Year",
            nameLocation: "middle",
            nameGap: 26,
            type: "category",
            axisTick: {
                show: false
            }
        },
        yAxis: {
            name: "Bids Invited, Responded, Confirmed Bidder, Executed",
            nameLocation: "middle",
            nameGap: 35,
            nameTextStyle: {
                fontWeight: 500,
                fontSize: 9
            }
        },
        series: [{
            type: "bar",
            barGap: 0,
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        },
        {
            type: "bar",
            seriesLayoutBy: "row"
        }
        ]
    };

    const monthlyTrendCurrentYearPopUpOption = {

        color: ["#256D85", "#7690EF", "#FFA600", "#FFCE72"],
        dataset: {
            source: [
                ["type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                ["Bids Invited", 90, 80, 95, 60, 75, 85, 50, 65, 75, 80, 65, 80],
                ["Responded", 85, 75, 90, 55, 70, 80, 45, 60, 70, 75, 60, 75],
                ["Confirmed Bidder", 80, 70, 85, 50, 65, 75, 40, 55, 65, 70, 55, 70],
                ["Executed", 75, 65, 80, 45, 60, 70, 35, 50, 60, 65, 50, 65],
            ]
        },
        grid: {
            show: false,
            top: 7,
            right: 10,
            left: "16%",
            bottom: "38%"
        },
        legend: {
            show: true,
            bottom: 30
        },
        xAxis: {
            name: "Financial Year",
            nameLocation: "middle",
            nameGap: 26,
            type: "category",
            axisTick: {
                show: false
            }
        },
        yAxis: {
            min: 0,
            interval: 15,
            max: 105,
            name: "Bids Invited, Responded, Confirmed Bidder, Executed",
            nameLocation: "middle",
            nameGap: 35,
            nameTextStyle: {
                // color: 'red',
                // fontStyle: 'italic',
                fontWeight: 500,
                fontSize: 9
            }
        },
        series: [{
            type: "bar",
            barGap: 0,
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        }, {
            type: "bar",
            seriesLayoutBy: "row"
        },
        {
            type: "bar",
            seriesLayoutBy: "row"
        }
        ]
    };

    const avgBidResponseTimeTrendPopUpOption = {
        color: ['#FFAB01'],
        grid: {
            top: 10,
            left: 35,
            right: 0,
            bottom: 85
        },
        xAxis: {
            name: "Month",
            nameLocation: "middle",
            nameGap: 26,
            type: 'category',
            axisTick: {
                show: false
            },
            data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        },
        yAxis: {
            name: "Avg. Bid Response Time (Days)",
            nameLocation: "middle",
            nameGap: 23,
            type: 'value',
            min: 2,
            max: 14,
            interval: 2
        },
        series: [
            {
                data: [6, 4, 5, 8, 7, 6, 8, 8, 11, 12, 10, 12],
                type: 'line'
            }
        ]
    };

    const topTradeLinesByExecutionsPopupOption = {
        color: ['#029046'],
        grid: {
            show: false,
            top: 7,
            right: 0,
            left: 47,
            bottom: 55
        },
        xAxis: {
            name: "Trade Line",
            nameLocation: "middle",
            nameGap: 30,
            type: 'category',
            data: ['Trade Line 1', 'Trade Line 2', 'Trade Line 3', 'Trade Line 4', 'Trade Line 5'],
            axisTick: {
                show: false
            }
        },
        yAxis: {
            name: "Count of Inquiries Executed",
            nameLocation: "middle",
            nameGap: 34,
            type: 'value',
            min: 0,
            interval: 200,
            max: 1200
        },
        series: [
            {
                data: [1023, 980, 810, 1051, 1000],
                type: 'bar',
                label: {
                    show: true,
                    position: 'top',
                    valueAnimation: true
                }
            },
        ]
    };

    const topReasonsForFreightForwarderBids = {
        color: ['#48B52C'],
        grid: {
            top: 0,
            left: 55,
            right: 0,
            bottom: 70
        },
        xAxis: {
            name: "Month",
            nameLocation: "middle",
            nameGap: 26,
            type: 'value',
            axisLine: {
                show: true
            },
            min: 0,
            interval: 5,
            max: 50,
            axisLabel: {
                formatter: '{value}%'
            }
        },
        yAxis: {
            data: ["Others", "Time", "FF", "Services", "Price"],
            type: 'category',
            axisTick: {
                show: false
            },
        },
        series: [
            {
                data: [20, 25, 30, 35, 40],
                type: 'bar',
                label: {
                    show: true,
                    position: 'right',
                    formatter: function (d) {
                        return d.data + "%";
                    },
                    valueAnimation: true
                }
            }
        ]
    };

    const topReasonsForDecliningFreightForwarderBids = {
        color: ['#F12247'],
        grid: {
            top: 0,
            left: 55,
            right: 0,
            bottom: 70
        },
        xAxis: {
            name: "Month",
            nameLocation: "middle",
            nameGap: 26,
            type: 'value',
            axisLine: {
                show: true
            },
            min: 0,
            interval: 5,
            max: 50,
            axisLabel: {
                formatter: '{value}%'
            }
        },
        yAxis: {
            data: ["Others", "Time", "FF", "Services", "Price"],
            type: 'category',
            axisTick: {
                show: false
            },
        },
        series: [
            {
                data: [20, 25, 30, 35, 40],
                type: 'bar',
                label: {
                    show: true,
                    position: 'right',
                    formatter: function (d) {
                        return d.data + "%";
                    },
                    valueAnimation: true
                }
            }
        ]
    };

    async function BindList() {



        console.log("ID", financialYearId)

        //financial Year date
        var currentDate1 = new Date();
        var beginDate = currentDate1.setDate(currentDate1.getDate() - 15);
        var endDate = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
        setFromDateOfEnquiry(currentDate1);
        setToDateOfEnquiry(new Date());
        var startDate = moment(beginDate).format();
        var lastDate = moment(endDate).format();

        var inquiryResponse = await DataStore.query(Inquiry,
            c => c.inquiryDate.between(startDate, lastDate)
        );
        //set Total Inquiry



        //Country List 
        var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var financialYearArray = []; financialYearArray = ConvertResponseForSelect(financialYearResponses); setfinancialYears(financialYearArray);


        //Service List 
        var serviceResponses = await DataStore.query(InquiryServices, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var serviceArray = []; serviceArray = ConvertResponseForSelect(serviceResponses); setService(serviceArray);
    }


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        BindList();
        getFilter();
        if (financialYearId || serviceId)
            getFilter()

    }, [financialYearId, serviceId]);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="dashboard-main-wrapper dashboardproconnect pl-16" x-data="{tab: 1}">
                <div className="p-5 pb-5">
                    <div className="flex justify-between sm:flex items-center space-x-8">
                        <div className='flex gap-4'>
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/dashboard" className="active">Summary</Link></li>
                                    <li><Link to="/employee/dashboard/detailed">Detailed</Link></li>
                                    <li><Link to="/employee/dashboard/drilldown">Drilldown</Link></li>
                                    <li><Link to="/employee/dashboard/geospatial">GeoSpatial</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='flex gap-4'>
                            <div className='block'>
                                <label className='text-[12px] font-normal text-[#344054]'>Financial Year</label>
                                <Select placeholder='Financial Year'
                                    value={financialYear}
                                    options={financialYears}

                                    onChange={e => {
                                        setFinancialYearId(e.value)
                                        setFinancialYear(e)
                                    }}
                                    className="mt-1 my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                            <div className='block'>
                                <label className='text-[12px] font-normal text-[#344054]'>Services</label>
                                <Select placeholder='Services'
                                    value={services}
                                    options={service}

                                    onChange={e => {
                                        setServices(e)
                                        setServiceId(e.value)
                                    }} className="mt-1 my-react-select-container"
                                    classNamePrefix="my-react-select" />
                            </div>
                        </div>
                    </div>



                    <div className='mt-10 flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1  gap-3 mb-10 overallstatus'>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4 items-center'>
                                <i className='icon monitor_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Inquires - Total</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white' >{totalInquiryCount} </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiresopen_icon'></i>

                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Inquires - Open</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>{openInquiryCount} </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiresaccpt_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Inquires - Accepted</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>700 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiesdecline_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Inquires - Declined</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>10 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiesclose_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Inquires - Closed</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>{closedInquiryResponse} </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquieslifeline_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Avg. Inquiry Lifecycle</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>800 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon revenue_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Revenue</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>$700 M </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon income_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Gross Margin</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>$70 M</h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                    <div className='mt-5 flex-row grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1  gap-3 mb-10'>
                        <div className="col-span-4 bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5  boxshadow">
                            <div className='flex justify-between mb-8 items-center'>
                                <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-0 dark:text-white">Trend Analysis - 5 Years </h6>
                                <div className='flex gap-2 items-center viewby'>
                                    <p className='text-[12px] text-[#98A2B3]'>View By</p>
                                    <Select placeholder='Inquiry & Execution' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />
                                </div>
                            </div>

                            <div className="card h-60 mb-2">
                                <ReactEChart option={trendAnalysisFiveYearsOption} />
                            </div>

                        </div>

                        <div className="col-span-8 bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5 boxshadow">

                            <div className='flex justify-between mb-10 items-center'>
                                <h6 className="text-[#101828] text-[18px] font-semibold text-left  dark:text-white">Ranking by % Conversion </h6>

                                <div className='flex gap-2 items-center viewby'>
                                    <p className='text-[12px] text-[#98A2B3]'>View By</p>
                                    <Select placeholder='Freight Forwarders' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />

                                    <Link to='' className=" py-1.5 px-3 bg-white text-[#344054] transition ease-in duration-200 text-[12px] text-center font-medium  focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]" >Bottom 5</Link>
                                    <Link to='' className="py-1.5 px-3 bg-[#029046] text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  focus:outline-none leading-6  justify-center border border-[#D0D5DD] rounded-md" >Top 5</Link>
                                </div>

                            </div>

                            <div className='mt-8 flex grow gap-6 justify-center'>
                                <Link to='' onClick={() => setShowCreatePopUp(1)} ><img src={Freight_Forwarders1} /></Link>

                                <Link to='' onClick={() => setShowCreatePopUp(1)} >
                                    <img src={Freight_Forwarders2} /></Link>
                                <Link to='' onClick={() => setShowCreatePopUp(1)} ><img src={Freight_Forwarders3} /></Link>
                                <Link to='' onClick={() => setShowCreatePopUp(1)} > <img src={Freight_Forwarders4} /></Link>
                                <Link to='' onClick={() => setShowCreatePopUp(1)} ><img src={Freight_Forwarders5} />
                                </Link>
                            </div>

                        </div>

                    </div>
                    <div className='mt-5 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1  gap-3 mb-10'>


                        <div className=" bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex justify-between mb-8 items-center'>
                                <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-5 dark:text-white">Monthly Trend - Current Year</h6>

                                <div className='flex gap-2 items-center viewby'>
                                    <p className='text-[12px] text-[#98A2B3]'>View By</p>
                                    <Select placeholder='Inquiry & Execution' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />
                                </div>
                            </div>
                            <div className="card h-60 mb-2">
                                <ReactEChart option={monthlyTrendCurrentYearOption} />
                            </div>

                        </div>



                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-5 dark:text-white">Average Inquiry Lifecycle Trend - Current Year</h6>
                            <div className="card h-60">
                                <ReactEChart option={averageInquiryLifecycleTrendCurrentYearOption} />
                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-5 dark:text-white">Top 5 Reasons for Quotation Decline by Customers</h6>
                            <div className="card h-60">
                                <ReactEChart option={TopReasonsForQuotationDeclineByCustomersOption} />
                            </div>
                        </div>

                    </div>







                    <div id="selector" className={isShowCreatePopUp === 1 ? 'overlay' : ''}></div>

                    {
                        isShowCreatePopUp === 1 ?
                            <div>
                                <div className="pt-16">

                                    <div style={{ width: "90vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav ranking_wrapper">

                                        <div className="popup_header relative">

                                            <div className="absolute right-0 left-0 top-0 w-full text-white">
                                                <div className="flex justify-between px-6 py-2">


                                                </div>

                                            </div>
                                        </div>
                                        <div className="p-0">
                                            <div className='mt-0 flex-row grid 2xl:grid-cols-10 xl:grid-cols-10 lg:grid-cols-10 md:grid-cols-10 sm:grid-cols-1  gap-0 '>
                                                <div className='grow col-span-4 p-6'>
                                                    <div className='relative border border-[#785245] p-5 rounded-lg'>
                                                        {/* <div 
                                    className='absolute top-0 bg-[#634237] text-[#80C7A2] text-[20px] font-light px-4 py-3 rounded-lg' > Freight Forwarder Rank Board</div> */}


                                                        <div className='w-full mt-5'>
                                                            <img src={Freight_Forwarderslg} className='w-48 m-auto' />
                                                        </div>

                                                        <div className='mt-8'>
                                                            <div className='flex justify-between h-24 bg-[#542B1FCC] py-6 px-6 rounded-lg mb-3 items-center'>
                                                                <div className=' text-[#B3DDC7] text-[18px] font-medium '>
                                                                    Bids Invited
                                                                </div>
                                                                <div className='relative'>
                                                                    <img src={PremiumBadge} className='w-14 m-auto' />
                                                                    <div className='flex w-full absolute justify-center top-2 mt-1  font-medium text-[#002B15] text-[20px]'>1</div>
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between h-24 bg-[#542B1FCC] py-6 px-6 rounded-lg mb-3 items-center'>
                                                                <div className=' text-[#B3DDC7] text-[18px] font-medium '>
                                                                    Bid Response
                                                                </div>
                                                                <div className='relative'>
                                                                    <img src={PremiumBadge} className='w-14 m-auto' />
                                                                    <div className='flex w-full absolute justify-center top-2 mt-1  font-medium text-[#002B15] text-[20px]'>4</div>
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between h-24 bg-[#542B1FCC] py-6 px-6 rounded-lg mb-3 items-center'>
                                                                <div className=' text-[#B3DDC7] text-[18px] font-medium '>
                                                                    Avg. Bid Response Time
                                                                </div>
                                                                <div className='relative'>
                                                                    <img src={PremiumBadge} className='w-14 m-auto' />
                                                                    <div className='flex w-full absolute justify-center top-2 mt-1  font-medium text-[#002B15] text-[20px]'>3</div>
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between h-24 bg-[#542B1FCC] py-6 px-6 rounded-lg mb-3 items-center'>
                                                                <div className=' text-[#B3DDC7] text-[18px] font-medium '>
                                                                    Confirmed Bids
                                                                </div>
                                                                <div className='relative'>
                                                                    <img src={PremiumBadge} className='w-14 m-auto' />
                                                                    <div className='flex w-full absolute justify-center top-2 mt-1  font-medium text-[#002B15] text-[20px]'>2</div>
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between h-24 bg-[#542B1FCC] py-6 px-6 rounded-lg mb-3 items-center'>
                                                                <div className=' text-[#B3DDC7] text-[18px] font-medium '>
                                                                    % Success
                                                                </div>
                                                                <div className='relative'>
                                                                    <img src={PremiumBadge} className='w-14 m-auto' />
                                                                    <div className='flex w-full absolute justify-center top-2 mt-1  font-medium text-[#002B15] text-[20px]'>5</div>
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between h-24 bg-[#542B1FCC] py-6 px-6 rounded-lg mb-3 items-center'>
                                                                <div className=' text-[#B3DDC7] text-[18px] font-medium '>
                                                                    Quote Executed
                                                                </div>
                                                                <div className='relative'>
                                                                    <img src={PremiumBadge} className='w-14 m-auto' />
                                                                    <div className='flex w-full absolute justify-center top-2 mt-1  font-medium text-[#002B15] text-[20px]'>6</div>
                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>




                                                </div>

                                                <div className='col-span-6 right-side' >
                                                    <div className='px-6'>
                                                        <div className='flex justify-end mt-1 px-2 mb-6 mt-4'>

                                                            <div><Link onClick={() => setShowCreatePopUp(0)} className="text-2xl leading-none removeClass"><img src={CloseBtn} /></Link></div>
                                                        </div>

                                                        <div className='flex justify-between items-center mb-5'>
                                                            <h4 class="text-2xl font-semibold text-[#101828] dark:text-white">Overview</h4>

                                                            <div className='flex gap-4'>
                                                                <div className='block'>
                                                                    <label className='text-[12px] font-normal text-[#344054]'>Financial Year</label>
                                                                    <Select placeholder='Financial Year' className="mt-1 my-react-select-container"
                                                                        classNamePrefix="my-react-select" />
                                                                </div>
                                                                <div className='block'>
                                                                    <label className='text-[12px] font-normal text-[#344054]'>Services</label>
                                                                    <Select placeholder='Services' className="mt-1 my-react-select-container"
                                                                        classNamePrefix="my-react-select" />
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div className='mt-10 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1  gap-3 mb-10 overallstatus mb-6'>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4 items-center'>
                                                                    <i className='icon monitor_icon'></i>
                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Total Bids Invited</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>100 </h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4'>
                                                                    <i className='icon inquiresopen_icon'></i>

                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>FF Responded</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>60 </h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4'>
                                                                    <i className='icon inquiesdecline_icon'></i>
                                                                    {/* <i className='icon inquiresaccpt_icon'></i> */}
                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>FF Declinded</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>20 </h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4'>
                                                                    {/* <i className='icon inquiesdecline_icon'></i> */}
                                                                    <i className='icon inquieslifeline_icon'></i>
                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Avg. Bid Response Time</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>5 Days </h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4'>
                                                                    <i className='icon inquiesclose_icon'></i>
                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>FF No Response</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>20 </h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4'>
                                                                    <i className='icon inquiesclose_icon'></i>
                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>FF Confirmed Bidder</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>30 </h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4'>
                                                                    {/* <i className='icon revenue_icon'></i> */}
                                                                    <i className='icon inquiresaccpt_icon'></i>
                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>FF - % Success</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>30% </h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                                                                <div className='flex gap-4'>
                                                                    <i className='icon inquiesclose_icon'></i>
                                                                    <div>
                                                                        <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>FF - Execution</h6>
                                                                        <div className="flex gap-2 items-center">
                                                                            <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>25</h5>
                                                                            <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                                                                LP <span className="text-[#00B42A]">
                                                                                    <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='mt-5 flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1  gap-3 mb-10'>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5  boxshadow">
                                                                <div className='flex justify-between mb-8 items-center'>
                                                                    <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-0 dark:text-white">Trend Analysis - 5 Years </h6>

                                                                </div>
                                                                <div className="h-60 mb-2">
                                                                    <ReactEChart option={trendAnalysisFiveYearsPopUpOption} />
                                                                </div>

                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5  boxshadow">
                                                                <div className='flex justify-between mb-8 items-center'>
                                                                    <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-0 dark:text-white">Monthly Trend - Current Year </h6>

                                                                </div>
                                                                <div className="h-60 mb-2">
                                                                    <ReactEChart option={monthlyTrendCurrentYearPopUpOption} />
                                                                </div>

                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5  boxshadow">
                                                                <div className='flex justify-between mb-8 items-center'>
                                                                    <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-0 dark:text-white">
                                                                        Avg. Bid Response Time Trend - Current Year </h6>

                                                                </div>
                                                                <div className="card h-60">
                                                                    <ReactEChart option={avgBidResponseTimeTrendPopUpOption} />
                                                                </div>

                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5  boxshadow">
                                                                <div className='flex justify-between mb-8 items-center'>
                                                                    <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-0 dark:text-white">
                                                                        Top 5 Trade Lines by Executions </h6>

                                                                </div>
                                                                <div className="card h-60 mb-2">
                                                                    <ReactEChart option={topTradeLinesByExecutionsPopupOption} />
                                                                </div>

                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5  boxshadow">
                                                                <div className='flex justify-between mb-8 items-center'>
                                                                    <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-0 dark:text-white">
                                                                        Top 5 Reasons for Confirming Freight Forwarders Bid</h6>

                                                                </div>
                                                                <div className="card h-60 mb-2">
                                                                    <ReactEChart option={topReasonsForFreightForwarderBids} />
                                                                </div>

                                                            </div>
                                                            <div className="bg-white dark:bg-[#2A2E32] py-5 rounded-lg px-5  boxshadow">
                                                                <div className='flex justify-between mb-8 items-center'>
                                                                    <h6 className="text-[#101828] text-[18px] font-semibold text-left mb-0 dark:text-white">
                                                                        Top 5 Reasons for Declining Freight Forwarders Bid</h6>

                                                                </div>
                                                                <div className="card h-60 mb-2">
                                                                    <ReactEChart option={topReasonsForDecliningFreightForwarderBids} />
                                                                </div>

                                                            </div>


                                                        </div>






                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div id="selector"></div>
                                </div>
                            </div> : null
                    }


                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}