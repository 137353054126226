import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
import DirectionImg from '../../assets/images/direction.png';

// import React, { useState } from 'react';
// import { Link } from "react-router-dom";

import Select from 'react-select';
import ReactEChart from "echarts-for-react";

export default function EmployeeDashboardGeoSpatial() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);





    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="dashboard-main-wrapper dashboardproconnect pl-16" x-data="{tab: 1}">
            <div className="p-5 pb-5">
                    <div className="flex justify-between sm:flex items-center space-x-8">
                        <div className='flex gap-4'>
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/dashboard" >Summary</Link></li>
                                    <li><Link to="/employee/dashboard/detailed">Detailed</Link></li>
                                    <li><Link to="/employee/dashboard/drilldown" >Drilldown</Link></li>
                                    <li><Link to="/employee/dashboard/geospatial" className="active">GeoSpatial</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='flex gap-4'>
                            <div className='block'>
                                <label className='text-[12px] font-normal text-[#344054]'>Financial Year</label>
                                <Select placeholder='Financial Year' className="mt-1 my-react-select-container"
                                    classNamePrefix="my-react-select" />
                            </div>
                            <div className='block'>
                                <label className='text-[12px] font-normal text-[#344054]'>Services</label>
                                <Select placeholder='Services' className="mt-1 my-react-select-container"
                                    classNamePrefix="my-react-select" />
                            </div>
                        </div>
                    </div>


                    <div className='flex-row grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1 mb-5'>
                        <div className="col-span-9">
                            <div className='mt-6 flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1  gap-3 mb-10 overallstatus bg-[#EEF8F4] p-4 rounded-tl-lg rounded-bl-lg  border border-[#E4E7EC]'>
                                <div className="bg-[#E5F3EC] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4 items-center'>
                                        <i className='icon monitor_icon'></i>
                                        <div>
                                            <h6 className='text-[#344054] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>Inquires - Received</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>1000 </h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#E5F3EC] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4'>
                                        <i className='icon inquiresopen_icon'></i>

                                        <div>
                                            <h6 className='text-[#344054] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>Inquires - Lost</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>10 </h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#E5F3EC] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4'>
                                        <i className='icon inquiesdecline_icon'></i>
                                        <div>
                                            <h6 className='text-[#344054] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>Quotation - Submitted</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>700 </h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-[#344054] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4'>
                                        <i className='icon inquiresaccpt_icon'></i>

                                        <div>
                                            <h6 className='text-[#fff] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>Quotation - Accepted</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#fff] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>10 </h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-[#E5F3EC] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4'>
                                        <i className='icon inquiesclose_icon'></i>
                                        <div>
                                            <h6 className='text-[#344054] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>% Conversion</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>800 </h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-[#E5F3EC] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4'>
                                        <i className='icon revenue_icon'></i>
                                        <div>
                                            <h6 className='text-[#344054] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>Revenue ($)</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>800 </h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-[#E5F3EC] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4'>
                                        <i className='icon income_icon '></i>
                                        <div>
                                            <h6 className='text-[#344054] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>Gross Margin ($)</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>$70M </h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-[#E5F3EC] dark:bg-[#2A2E32] py-4 rounded-lg px-3">
                                    <div className='flex gap-4'>
                                        <i className='icon inquieslifeline_icon '></i>
                                        <div>
                                            <h6 className='text-[#344054] text-[15px] xl:text-[15px] lg:text-[15px] sm:text-[13px] 2xl:text-[15px] font-medium text-left dark:text-white leading-8'>Avg. Inquiry Lifecycle</h6>
                                            <div className="flex gap-2 items-center">
                                                <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>$70</h5>
                                                <div className='arrowicon text-[#86909C] text-[10px] font-light '>
                                                    LP <span className="text-[#00B42A]">
                                                        <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-span-4'>
                                    <div className='flex mt-3'>
                                        <h2 className='text-[#002B15] text-[18px] font-semibold'>Trade Lines - Airways</h2>
                                    </div>
                                    <div className='flex mt-2'>
                                        <p className='text-[#01813F] text-[14px] font-normal'>Financial Year <span className='bg-[#01813F] text-[#fff] text-[14px] font-normal ml-3 px-2 py-1 rounded-md'>2022 - 2023</span></p>

                                    </div>
                                </div>



                            </div>

                        </div>
                        <div className="col-span-3">
                            <div className='bg-[#344054] px-2 py-3 mt-6 text-center rounded-tr-lg'>
                                <h2 className='text-[#fff] text-[15px]'>Top 15 Trade Lines</h2>
                            </div>
                            <div className='bg-[#E5F3EC] border border-[#E4E7EC] px-2 py-1'>

                                <div className='grid grid-cols-8 my-4 px-4 gap-4 border-b'>
                                    <div className='col-span-2'>
                                        <div className='bg-[#fff] h-16 w-16 rounded-full text-center'>
                                            <p className='text-[#667085] text-[12px] pt-2'>Rank</p>
                                            <h6 className='text-[#101828] text-[20px] font-medium'>1</h6>
                                        </div>
                                    </div>
                                    <div className='col-span-6'>
                                        <div className='flex mb-2'>
                                            <h3 className='text-[#101828] text-[18px] font-medium'>
                                                89 Quotation Accepted
                                            </h3>

                                        </div>
                                        <div className='flex flex-row gap-3'>
                                            <img src={DirectionImg} />
                                            <div className='flex-col gap-3'>
                                                <div className='mb-3'>
                                                    <h6 className='text-[#344054] text-[14px] font-normal'>Kotoka, <span className='text-[#4FB155] text-[12px]'>Ghana</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Port of Orign </p>
                                                </div>
                                                <div>
                                                    <h6 className='text-[#344054] text-[14px]  font-normal'>Montreal, <span className='text-[#4FB155] text-[12px]'>Canada</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Destination</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='grid grid-cols-8 my-4 px-4 gap-4 border-b'>
                                    <div className='col-span-2'>
                                        <div className='bg-[#fff] h-16 w-16 rounded-full text-center'>
                                            <p className='text-[#667085] text-[12px] pt-2'>Rank</p>
                                            <h6 className='text-[#101828] text-[20px] font-medium'>2</h6>
                                        </div>
                                    </div>
                                    <div className='col-span-6'>
                                        <div className='flex mb-2'>
                                            <h3 className='text-[#101828] text-[18px] font-medium'>
                                                78 Quotation Accepted
                                            </h3>

                                        </div>
                                        <div className='flex flex-row gap-3'>
                                            <img src={DirectionImg} />
                                            <div className='flex-col gap-3'>
                                                <div className='mb-3'>
                                                    <h6 className='text-[#344054] text-[14px] font-normal'>Washington, <span className='text-[#4FB155] text-[12px]'>USA</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Port of Orign </p>
                                                </div>
                                                <div>
                                                    <h6 className='text-[#344054] text-[14px]  font-normal'>Kuala Lumpur, <span className='text-[#4FB155] text-[12px]'>Malaysia</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Destination</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='grid grid-cols-8 my-4 px-4 gap-4 border-b'>
                                    <div className='col-span-2'>
                                        <div className='bg-[#fff] h-16 w-16 rounded-full text-center'>
                                            <p className='text-[#667085] text-[12px] pt-2'>Rank</p>
                                            <h6 className='text-[#101828] text-[20px] font-medium'>3</h6>
                                        </div>
                                    </div>
                                    <div className='col-span-6'>
                                        <div className='flex mb-2'>
                                            <h3 className='text-[#101828] text-[18px] font-medium'>
                                                46 Quotation Accepted
                                            </h3>

                                        </div>
                                        <div className='flex flex-row gap-3'>
                                            <img src={DirectionImg} />
                                            <div className='flex-col gap-3'>
                                                <div className='mb-3'>
                                                    <h6 className='text-[#344054] text-[14px] font-normal'>Kotoka, <span className='text-[#4FB155] text-[12px]'>Ghana</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Port of Orign </p>
                                                </div>
                                                <div>
                                                    <h6 className='text-[#344054] text-[14px]  font-normal'>Montreal, <span className='text-[#4FB155] text-[12px]'>Canada</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Destination</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='grid grid-cols-8 my-4 px-4 gap-4 border-b'>
                                    <div className='col-span-2'>
                                        <div className='bg-[#fff] h-16 w-16 rounded-full text-center'>
                                            <p className='text-[#667085] text-[12px] pt-2'>Rank</p>
                                            <h6 className='text-[#101828] text-[20px] font-medium'>4</h6>
                                        </div>
                                    </div>
                                    <div className='col-span-6'>
                                        <div className='flex mb-2'>
                                            <h3 className='text-[#101828] text-[18px] font-medium'>
                                                60 Quotation Accepted
                                            </h3>

                                        </div>
                                        <div className='flex flex-row gap-3'>
                                            <img src={DirectionImg} />
                                            <div className='flex-col gap-3'>
                                                <div className='mb-3'>
                                                    <h6 className='text-[#344054] text-[14px] font-normal'>Kotoka, <span className='text-[#4FB155] text-[12px]'>Ghana</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Port of Orign </p>
                                                </div>
                                                <div>
                                                    <h6 className='text-[#344054] text-[14px]  font-normal'>Montreal, <span className='text-[#4FB155] text-[12px]'>Canada</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Destination</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-8 my-4 px-4 gap-4 border-b'>
                                    <div className='col-span-2'>
                                        <div className='bg-[#fff] h-16 w-16 rounded-full text-center'>
                                            <p className='text-[#667085] text-[12px] pt-2'>Rank</p>
                                            <h6 className='text-[#101828] text-[20px] font-medium'>5</h6>
                                        </div>
                                    </div>
                                    <div className='col-span-6'>
                                        <div className='flex mb-2'>
                                            <h3 className='text-[#101828] text-[18px] font-medium'>
                                                60 Quotation Accepted
                                            </h3>

                                        </div>
                                        <div className='flex flex-row gap-3'>
                                            <img src={DirectionImg} />
                                            <div className='flex-col gap-3'>
                                                <div className='mb-3'>
                                                    <h6 className='text-[#344054] text-[14px] font-normal'>Kotoka, <span className='text-[#4FB155] text-[12px]'>Ghana</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Port of Orign </p>
                                                </div>
                                                <div>
                                                    <h6 className='text-[#344054] text-[14px]  font-normal'>Montreal, <span className='text-[#4FB155] text-[12px]'>Canada</span></h6>
                                                    <p className='text-[#98A2B3] text-[12px]'>Destination</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}