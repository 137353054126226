import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import LeftSideBar from '../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../../components/helper/validateRole";
import EmpLogin from "../../../setup/auth/emp.component";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Inquiry, InquiryStatus, InquirySource, InquiryServices, Customer, SalesPerson, InquiryCargoType, Country, InquiryPort, InquiryBid } from '../../../models';
import { DataStore, Predicates, SortDirection, useQuery } from '@aws-amplify/datastore';
import uniqid from 'uniqid';
import moment from 'moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import Select from 'react-select'
import { ConvertResponseForSelectTwoNames, ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import { Calendar } from 'primereact/calendar';
import { toast } from 'react-toastify';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';

import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

export default function Inquiries() {


    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    
    // Search
    const { inquirySearchTxt } = useParams();

    //list
    const [inquiryList, setInquiryList] = useState([]);

    //state
    const [totalInquiry, setTotalInquiry] = useState("");

    const [requestorList, setRequestorList] = useState([]);
    const [salesPersonList, setSalesPersonList] = useState([]);
    const [inquiryServicesList, setInquiryServicesList] = useState([]);
    const [cargoTypeList, setCargoTypeList] = useState([]);

    //Filters
    const [isFilterMode, setFilterMode] = useState(false);
    const [requestorId, setRequestorId] = useState("");
    const [requestorName, setRequestorName] = useState("");
    const [salesPersonId, setSalesPersonId] = useState("");
    const [salesPersonName, setSalesPersonName] = useState("");
    const [inquiryServicesId, setInquiryServicesId] = useState("");
    const [inquiryServiceName, setInquiryServiceName] = useState("");
    const [cargoTypeId, setCargoTypeId] = useState("");
    const [cargoTypeName, setCargoTypeName] = useState("");
    const [fromdateOfEnquiry, setFromDateOfEnquiry] = useState();
    const [todateOfEnquiry, setToDateOfEnquiry] = useState("");

    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    const [inquiryIdFilterValue, setInquiryIdFilterValue] = useState('');
    const [serviceFilterValue, setServiceFilterValue] = useState('');


    //port data
    const [portList, setPortList] = useState([]);

    //pagination
    const [pageNo, setPageNo] = useState(0)
    const [totalInquiryCount, setTotalInquiryCount] = useState(0)
    const [paginationCount, setPaginationCount] = useState(1)
    const [paginationLimit] = useState(10)
    const [searchedText, setSearchedText] = useState("")
    const [isFilterChanged, setFilterChanged] = useState(false);

    const handlePageClick = (data) => {
        console.log("data----" + JSON.stringify(data));
        setTimeout(() => {
            setPageNo(Number(data.selected))
        }, 10);
    };

    // let FilterData = async () => {

    //     //get total inquiry count
    //     var totalInquiryCount = await DataStore.query(Inquiry);
    //     setTotalInquiryCount(totalInquiryCount.length);

    //     var currentDate1 = new Date();
    //     var beginDate = currentDate1.setDate(currentDate1.getDate() - 15);
    //     setFromDateOfEnquiry(currentDate1);
    //     setToDateOfEnquiry(new Date());

    //     let inquiryResponse;
    //     if (isFilterChanged) {
    //     }
    //     else {
    //         let getTotalRecords = await DataStore.query(Inquiry, Predicates.ALL);
    //         setPaginationCount(Math.round(getTotalRecords.length / paginationLimit) > 0 ? Math.round(getTotalRecords.length / paginationLimit) : 1)
    //         inquiryResponse = await DataStore.query(Inquiry, Predicates.ALL, {
    //             sort: s => s.createdAt(SortDirection.DESCENDING),
    //             page: pageNo,
    //             limit: paginationLimit,
    //         });

    //         let inquiryFinalData = []
    //         let currentRequestorName = ""
    //         let currentServiceName = ""
    //         let currentInquiryCargoTypeName = ""
    //         let originPort = ""
    //         let destinationPort = ""

    //         for (let i = 0; i < inquiryResponse.length; i++) {

    //             //set Inquiry Services 
    //             if (inquiryResponse[i].inquiryServiceId) {
    //                 var currentService = await DataStore.query(InquiryServices, inquiryResponse[i].inquiryServiceId);

    //                 if (currentService)
    //                     currentServiceName = currentService.name
    //                 else
    //                     currentServiceName = ""

    //             } else { currentServiceName = "" }

    //             //set Requestor 
    //             if (inquiryResponse[i].customerId) {
    //                 var currentRequestor = await DataStore.query(Customer, inquiryResponse[i].customerId);

    //                 if (currentRequestor)
    //                     currentRequestorName = currentRequestor.firstName + " " + currentRequestor.lastName
    //                 else
    //                     currentRequestorName = ""

    //             } else { currentRequestorName = "" }

    //             //set Inquiry Cargo Type 
    //             if (inquiryResponse[i].inquiryCargoTypeId) {
    //                 var currentInquiryCargoType = await DataStore.query(InquiryCargoType, inquiryResponse[i].inquiryCargoTypeId);

    //                 if (currentInquiryCargoType)
    //                     currentInquiryCargoTypeName = currentInquiryCargoType.name
    //                 else
    //                     currentInquiryCargoTypeName = ""

    //             } else { currentInquiryCargoTypeName = "" }

    //             //set originPort 
    //             if (inquiryResponse[i].inquiryOriginPort) {
    //                 var originPortResponse = await DataStore.query(InquiryPort, inquiryResponse[i].inquiryOriginPort);

    //                 if (originPortResponse)
    //                     originPort = originPortResponse.name
    //                 else
    //                     originPort = ""

    //             } else { originPort = "" }

    //             //set destinationPort 
    //             if (inquiryResponse[i].inquiryOriginPort) {
    //                 var destinationPortResponse = await DataStore.query(InquiryPort, inquiryResponse[i].inquiryDestinationPort);


    //                 // Dates
    //                 var inquiryBidResponse = await DataStore.query(InquiryBid);


    //                 var invite = inquiryBidResponse[i].bidStatus;


    //                 // console.log("inquiryBidResponseinquiryBidResponse :-", invite);


    //                 if (destinationPortResponse)
    //                     destinationPort = destinationPortResponse.name
    //                 else
    //                     destinationPort = ""

    //             } else { destinationPort = "" }

    //             //get only bidInvited Data
    //             var bidInvitedData = await DataStore.query(InquiryBid, (c) => c.inquiryId.eq(inquiryResponse[i].id))

    //             var AllInviteDates = [];
    //             if (bidInvitedData.length > 0) {
    //                 bidInvitedData.map((item) => {
    //                     var inviteDate = item.createdAt;
    //                     AllInviteDates.push(inviteDate);
    //                 })
    //             }

    //             var quoteSubmittedDate = [];
    //             if (bidInvitedData.length > 0) {
    //                 bidInvitedData.map((item) => {
    //                     var approvedDate = item.approvedDat;
    //                     quoteSubmittedDate.push(approvedDate);
    //                 })
    //             }

    //             var cusomerStatusResponse = [];
    //             if (bidInvitedData.length > 0) {
    //                 bidInvitedData.map((item) => {
    //                     var cusomerStatusUpdated = item.customerStatusUpdatedOn;
    //                     cusomerStatusResponse.push(cusomerStatusUpdated);
    //                 })
    //             }

    //             var bidDateResponse = [];
    //             if (bidInvitedData.length > 0) {
    //                 bidInvitedData.map((item) => {
    //                     var bidDate = item.bidDate;
    //                     bidDateResponse.push(bidDate);
    //                 })
    //             }

    //             let data = {
    //                 "InquiryID": inquiryResponse[i].id,
    //                 "InquiryNo": inquiryResponse[i].inquiryNo,
    //                 "InquiryDate": inquiryResponse[i].inquiryDate === "1970-01-01T12:30:23.999Z" ? "-" : moment(inquiryResponse[i].inquiryDate).format('MM/DD/YYYY'),
    //                 "Service": currentServiceName,
    //                 "Inquirysource": InquirySource.WEBSITE,
    //                 "Requestor": currentRequestorName,
    //                 "OriginPort": originPort,
    //                 "DestinationPort": destinationPort,
    //                 "CargoType": currentInquiryCargoTypeName,
    //                 "Cargo Ready Date": inquiryResponse[i].inquiryCargoReadyDate === "1970-01-01Z" ? "-" : moment(inquiryResponse[i].inquiryCargoReadyDate).format('MM/DD/YYYY'),
    //                 "Contract Duration": "1 month",
    //                 "Inquiry Closing Date": inquiryResponse[i].inquiryClosingDate === "1970-01-01Z" ? "-" : moment(inquiryResponse[i].inquiryClosingDate).format('MM/DD/YYYY'),
    //                 "Bids Invited": AllInviteDates[0] ? moment(AllInviteDates[0]).format('MM/DD/YYYY') : "-",
    //                 "Bids Received": bidDateResponse[0] ? moment(bidDateResponse[0]).format('MM/DD/YYYY') : "-",
    //                 "Quote Submitted Date": quoteSubmittedDate[0] ? moment(quoteSubmittedDate[0]).format('MM/DD/YYYY') : "-",
    //                 "Customer Response Date": cusomerStatusResponse[0] ? moment(cusomerStatusResponse[0]).format('MM/DD/YYYY') : "-",
    //                 "Status": inquiryResponse[i].inquiryStatus,
    //                 "Inquiry Cycle Time": "5 days"
    //             }
    //             inquiryFinalData.push(data);

    //             if (i === inquiryResponse.length - 1) {
    //                 setInquiryList(inquiryFinalData)
    //             }
    //         }

    //     }
    // }

    // New filter starts

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRepresentative, setSelectedRepresentative] = useState(null);
    
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'name': { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            'company': { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' },
        }
    });



        const getCustomers = async(params) => {
    
            // console.log(params.lazyEvent);
            const obj = JSON.parse(params.lazyEvent);
            const keys = Object.keys(obj);
            let pageNumber = obj.page;

            var getAllInquiry = await DataStore.query(Inquiry, p => p.inquiryNo.contains(''))

            console.log(totalInquiryCount)
            var data = await DataStore.query(Inquiry, p => p.inquiryNo.contains(''),{
                sort: (s) => s.createdAt(SortDirection.DESCENDING),
                page: pageNumber,
                limit: 10
               })
               
               console.log(data)

               let returnData = [];
               returnData['inquiries'] = data;
               returnData['totalInquiries'] = getAllInquiry.length

            return returnData;
        }

    let loadLazyTimeout = null;

    // New filter end




    const [filters1, setFilters1] = useState({

        // global: { value: null, matchMode: FilterMatchMode.CONTAINS },

        'InquiryNo': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Service': { value: null, matchMode: FilterMatchMode.IN },


        // 'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // representative: { value: null, matchMode: FilterMatchMode.IN },
        // status: { value: null, matchMode: FilterMatchMode.EQUALS },
        // verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const onChangeRequestor = (e) => {
        // setFilterMode(false);
    }

    const onChangeSalePerson = (e) => {
        // setFilterMode(false);
    }

    const onChangeServiceId = (e) => {
        // setFilterMode(false);
    }
    const onChangeCargoId = (e) => {
        // setFilterMode(false);
    }
    const onChangeFromDateInquiry = (e) => {
        // setFilterMode(true);
    }
    const onChangeToDateInquiry = (e) => {
        if (fromdateOfEnquiry === '') { toast.error('Please Select from date.'); return; }
        // setFilterMode(true);
    }


    const getFilter = async () => {

        let setFilter = lazyParams;
        setFilter['first'] = 0;
        setLazyParams(setFilter);

        loadLazyData();

        // if (fromdateOfEnquiry) {
        //     var fromDate = moment(fromdateOfEnquiry).format();
        //     var toDate = moment(todateOfEnquiry).format();
        //     var inquiryResponse = await DataStore.query(Inquiry,
        //         (c) => c.and(c => [
        //             c.inquiryDate.ge(fromDate),
        //             c.inquiryDate.le(toDate),
        //             c.inquiryServiceId.contains(inquiryServicesId),
        //             c.customerId.contains(requestorId),
        //             c.inquiryCargoTypeId.contains(cargoTypeId),
        //             c.salesPersonId.contains(salesPersonId)
        //         ]),

        //     );
        // } else {
        //     var inquiryResponse = await DataStore.query(Inquiry,
        //         (c) => c.and(c => [
        //             c.inquiryServiceId.contains(inquiryServicesId),
        //             c.customerId.contains(requestorId),
        //             c.inquiryCargoTypeId.contains(cargoTypeId),
        //             c.salesPersonId.contains(salesPersonId),
        //         ]),

        //     );
        // }

        // setTotalInquiry(inquiryResponse.length)

        // let inquiryFinalData = []
        // let currentRequestorName = ""
        // let currentServiceName = ""
        // let currentInquiryCargoTypeName = ""
        // let originPort = ""
        // let destinationPort = ""

        // for (let i = 0; i < inquiryResponse.length; i++) {

        //     //set Inquiry Services 
        //     if (inquiryResponse[i].inquiryServiceId) {
        //         var currentService = await DataStore.query(InquiryServices, inquiryResponse[i].inquiryServiceId);

        //         if (currentService)
        //             currentServiceName = currentService.name
        //         else
        //             currentServiceName = ""

        //     } else { currentServiceName = "" }

        //     //set Requestor 
        //     if (inquiryResponse[i].customerId) {
        //         var currentRequestor = await DataStore.query(Customer, inquiryResponse[i].customerId);

        //         if (currentRequestor)
        //             currentRequestorName = currentRequestor.firstName + " " + currentRequestor.lastName
        //         else
        //             currentRequestorName = ""

        //     } else { currentRequestorName = "" }

        //     //set Inquiry Cargo Type 
        //     if (inquiryResponse[i].inquiryCargoTypeId) {
        //         var currentInquiryCargoType = await DataStore.query(InquiryCargoType, inquiryResponse[i].inquiryCargoTypeId);

        //         if (currentInquiryCargoType)
        //             currentInquiryCargoTypeName = currentInquiryCargoType.name
        //         else
        //             currentInquiryCargoTypeName = ""

        //     } else { currentInquiryCargoTypeName = "" }

        //     //set originPort 
        //     if (inquiryResponse[i].inquiryCargoTypeId) {
        //         var originPortResponse = await DataStore.query(InquiryPort, inquiryResponse[i].inquiryOriginPort);

        //         if (originPortResponse)
        //             originPort = originPortResponse.name
        //         else
        //             originPort = ""

        //     } else { originPort = "" }

        //     //set destinationPort 
        //     if (inquiryResponse[i].inquiryCargoTypeId) {
        //         var destinationPortResponse = await DataStore.query(InquiryPort, inquiryResponse[i].inquiryDestinationPort);

        //         if (destinationPortResponse)
        //             destinationPort = destinationPortResponse.name
        //         else
        //             destinationPort = ""

        //     } else { destinationPort = "" }

        //     let data = {
        //         "InquiryID": inquiryResponse[i].id,
        //         "InquiryNo": inquiryResponse[i].inquiryNo,
        //         "InquiryDate": inquiryResponse[i].inquiryDate === "1970-01-01T12:30:23.999Z" ? "" : moment(inquiryResponse[i].inquiryDate).format('MM/DD/YYYY'),
        //         "Service": currentServiceName,
        //         "Inquirysource": InquirySource.WEBSITE,
        //         "Requestor": currentRequestorName,
        //         "OriginPort": originPort,
        //         "DestinationPort": destinationPort,
        //         "CargoType": currentInquiryCargoTypeName,
        //         "Cargo Ready Date": inquiryResponse[i].inquiryCargoReadyDate === "1970-01-01Z" ? "" : moment(inquiryResponse[i].inquiryCargoReadyDate).format('MM/DD/YYYY'),
        //         "Contract Duration": "1 month",
        //         "Inquiry Closing Date": inquiryResponse[i].inquiryClosingDate === "1970-01-01Z" ? "" : moment(inquiryResponse[i].inquiryClosingDate).format('MM/DD/YYYY'),

        //         // "Bids Invited": "12/25/22",
        //         "Bids Invited": "1111",

        //         "Bids Received": "12/25/22",
        //         "Quote Submitted Date": "12/25/22",
        //         "Customer Response Date": "12/25/22",
        //         "Status": inquiryResponse[i].inquiryStatus,
        //         "Inquiry Cycle Time": "77 days"
        //     }
        //     inquiryFinalData.push(data);
        // }

        // setInquiryList(inquiryFinalData);

    }


    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }


    const searchFunction1 = (value) => {
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }



    const initFilters1 = (sample) => {
        setFilters1({
            'global': { value: sample, matchMode: FilterMatchMode.CONTAINS },
            'service': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'Service': { value: null, matchMode: FilterMatchMode.IN },
            'Requestor': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'originport': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'OriginPort': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'originport': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'InquiryNo': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'Inquirysource': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'InquiryDate': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'DestinationPort': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'CargoType': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'Status': { value: null, matchMode: FilterMatchMode.CONTAINS }
        });
        setGlobalFilterValue1('');
    }

    const navigateTo = useNavigate()

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //* Add Inquiry
    const AddInquiry = async () => {
        await DataStore.save(
            new Inquiry({
                "inquiryNo": moment().format('YYYYDDMMHHmmSS'),
                "inquiryDate": moment().format("YYYY-MM-DD") + 'T' + moment().format("HH:mm:ss") + '.999Z',
                // "inquiryDate": "1970-01-01T12:30:23.999Z",
                "inquiryServiceId": "",
                "inquirySource": InquirySource.WEBSITE,
                "inquiryOriginPort": "",
                "inquiryDestinationPort": "",
                "inquiryCargoTypeId": "",
                "inquiryCargoReadyDate": "1970-01-01Z",
                "contractDuration": 0,
                "inquiryClosingDate": "1970-01-01Z",
                "inquiryStatus": InquiryStatus.OPEN,
                "productDetails": "",
                "internationalRef": "",
                "termsConditions": "",
                "originCountry": "",
                "destinationCountry": "",
                "customerId": "",
                "salesPersonId": ""
            })
        ).then((response) => {
            if (response.id !== "") {
                navigateTo('/employee/inquiry/' + response.id)
                reactLocalStorage.set('inquiryNo', response.inquiryNo);
                reactLocalStorage.set('isInquiryEdit', false);
            }
        })
    }

    async function FiltersData() {

        //Filter Loads
        var requestorList = await DataStore.query(Customer, Predicates.ALL, {
            sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        });
        var myRequestorArray = []; myRequestorArray = ConvertResponseForSelectTwoNames(requestorList);
        setRequestorList(myRequestorArray)


        var salesPersonList = await DataStore.query(SalesPerson, Predicates.ALL, {
            sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        });
        var mySalesPersonArray = []; mySalesPersonArray = ConvertResponseForSelectTwoNames(salesPersonList);
        setSalesPersonList(mySalesPersonArray)

        //set InquiryServices List
        var inquiryServicesList = await DataStore.query(InquiryServices, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myInquiryServicesArray = []; myInquiryServicesArray = ConvertResponseForSelect(inquiryServicesList);
        setInquiryServicesList(myInquiryServicesArray)

        //set cargo Type List
        var cargoTypeList = await DataStore.query(InquiryCargoType, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myCargoTypeArray = []; myCargoTypeArray = ConvertResponseForSelect(cargoTypeList);
        setCargoTypeList(myCargoTypeArray)


        //fetch all port list
        var portList = await DataStore.query(InquiryPort, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myPortTypeArray = []; myPortTypeArray = ConvertResponseForSelect(portList);
        setPortList(myPortTypeArray)


    }

    //BindList
    async function BindList() {

        //get total inquiry count
        var totalInquiryCount = await DataStore.query(Inquiry);
        setTotalInquiryCount(totalInquiryCount.length);

        var currentDate1 = new Date();
        var beginDate = currentDate1.setDate(currentDate1.getDate() - 15);

        var startDate = moment(beginDate).format();
        var endDate = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
        setFromDateOfEnquiry(currentDate1);
        setToDateOfEnquiry(new Date());
        var lastDate = moment(endDate).format();

        //pagination 
        var inquiryResponse = await DataStore.query(Inquiry, Predicates.ALL,
            c => c.inquiryDate.between(startDate, lastDate)
        );

        setTotalInquiry(inquiryResponse.length)

        let inquiryFinalData = []
        let currentRequestorName = ""
        let currentServiceName = ""
        let currentInquiryCargoTypeName = ""
        let originPort = ""
        let destinationPort = ""

        for (let i = 0; i < inquiryResponse.length; i++) {

            //set Inquiry Services 
            if (inquiryResponse[i].inquiryServiceId) {
                var currentService = await DataStore.query(InquiryServices, inquiryResponse[i].inquiryServiceId);

                if (currentService)
                    currentServiceName = currentService.name
                else
                    currentServiceName = ""

            } else { currentServiceName = "" }

            //set Requestor 
            if (inquiryResponse[i].customerId) {
                var currentRequestor = await DataStore.query(Customer, inquiryResponse[i].customerId);

                if (currentRequestor)
                    currentRequestorName = currentRequestor.firstName + " " + currentRequestor.lastName
                else
                    currentRequestorName = ""

            } else { currentRequestorName = "" }

            //set Inquiry Cargo Type 
            if (inquiryResponse[i].inquiryCargoTypeId) {
                var currentInquiryCargoType = await DataStore.query(InquiryCargoType, inquiryResponse[i].inquiryCargoTypeId);

                if (currentInquiryCargoType)
                    currentInquiryCargoTypeName = currentInquiryCargoType.name
                else
                    currentInquiryCargoTypeName = ""

            } else { currentInquiryCargoTypeName = "" }

            //set originPort 
            if (inquiryResponse[i].inquiryOriginPort) {
                var originPortResponse = await DataStore.query(InquiryPort, inquiryResponse[i].inquiryOriginPort);

                if (originPortResponse)
                    originPort = originPortResponse.name
                else
                    originPort = ""

            } else { originPort = "" }

            //set destinationPort 
            if (inquiryResponse[i].inquiryOriginPort) {
                var destinationPortResponse = await DataStore.query(InquiryPort, inquiryResponse[i].inquiryDestinationPort);


                // Dates
                var inquiryBidResponse = await DataStore.query(InquiryBid);


                var invite = inquiryBidResponse[i].bidStatus;


                // console.log("inquiryBidResponseinquiryBidResponse :-", invite);


                if (destinationPortResponse)
                    destinationPort = destinationPortResponse.name
                else
                    destinationPort = ""

            } else { destinationPort = "" }

            //get only bidInvited Data
            var bidInvitedData = await DataStore.query(InquiryBid, (c) => c.inquiryId.eq(inquiryResponse[i].id))

            var AllInviteDates = [];
            if (bidInvitedData.length > 0) {
                bidInvitedData.map((item) => {
                    var inviteDate = item.createdAt;
                    AllInviteDates.push(inviteDate);
                })
            }

            var quoteSubmittedDate = [];
            if (bidInvitedData.length > 0) {
                bidInvitedData.map((item) => {
                    var approvedDate = item.approvedDat;
                    quoteSubmittedDate.push(approvedDate);
                })
            }

            var cusomerStatusResponse = [];
            if (bidInvitedData.length > 0) {
                bidInvitedData.map((item) => {
                    var cusomerStatusUpdated = item.customerStatusUpdatedOn;
                    cusomerStatusResponse.push(cusomerStatusUpdated);
                })
            }

            var bidDateResponse = [];
            if (bidInvitedData.length > 0) {
                bidInvitedData.map((item) => {
                    var bidDate = item.bidDate;
                    bidDateResponse.push(bidDate);
                })
            }

            let data = {
                "InquiryID": inquiryResponse[i].id,
                "InquiryNo": inquiryResponse[i].inquiryNo,
                "InquiryDate": inquiryResponse[i].inquiryDate === "1970-01-01T12:30:23.999Z" ? "-" : moment(inquiryResponse[i].inquiryDate).format('MM/DD/YYYY'),
                "Service": currentServiceName,
                "Inquirysource": InquirySource.WEBSITE,
                "Requestor": currentRequestorName,
                "OriginPort": originPort,
                "DestinationPort": destinationPort,
                "CargoType": currentInquiryCargoTypeName,
                "Cargo Ready Date": inquiryResponse[i].inquiryCargoReadyDate === "1970-01-01Z" ? "-" : moment(inquiryResponse[i].inquiryCargoReadyDate).format('MM/DD/YYYY'),
                "Contract Duration": "1 month",
                "Inquiry Closing Date": inquiryResponse[i].inquiryClosingDate === "1970-01-01Z" ? "-" : moment(inquiryResponse[i].inquiryClosingDate).format('MM/DD/YYYY'),
                "Bids Invited": AllInviteDates[0] ? moment(AllInviteDates[0]).format('MM/DD/YYYY') : "-",
                "Bids Received": bidDateResponse[0] ? moment(bidDateResponse[0]).format('MM/DD/YYYY') : "-",
                "Quote Submitted Date": quoteSubmittedDate[0] ? moment(quoteSubmittedDate[0]).format('MM/DD/YYYY') : "-",
                "Customer Response Date": cusomerStatusResponse[0] ? moment(cusomerStatusResponse[0]).format('MM/DD/YYYY') : "-",
                "Status": inquiryResponse[i].inquiryStatus,
                "Inquiry Cycle Time": "5 days"
            }
            inquiryFinalData.push(data);
        }
        setInquiryList(inquiryFinalData)
    }

    //onclick inquiry Id
    const inquiryIdTemplate = (rowData, column) => {
        return <div>
            <Link
                onClick={() => {
                    reactLocalStorage.set('inquiryNo', rowData.InquiryNo);
                    reactLocalStorage.set('isInquiryEdit', true);
                }}
                to={'/employee/inquiry/' + rowData.InquiryID}
            >{rowData.InquiryNo}
            </Link>
        </div>;
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-end searchbox">
                {/* <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} /> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        // value={globalFilterValue1}
                        defaultValue={inquirySearchTxt}
                        // value={inquirySearchTxt}
                        onChange={onGlobalFilterChange1}
                        placeholder="Keyword Search" />
                </span>
            </div>
        )
    }

    //filter for service
    const representatives1 = inquiryServicesList;
    const representativesItemTemplate = (option) => {
        // console.log("oppppp :-", option)
        return (
            <div className="p-multiselect-representative-option">
                <span className="vertical-align-middle ml-2">{option.label}</span>
            </div>
        );
    };
    const serviceRowFilterTemplate = (options) => {
        console.log("options------" + JSON.stringify(options))
        // console.log('optionsoptions... :-', options)
        // console.log('inquiryServicesList :-', inquiryServicesList)
        return (
            <MultiSelect
                value={options.value}
                // label={options.field}
                options={representatives1}
                itemTemplate={representativesItemTemplate}
                onChange={(e) => {
                    console.log('eee:-', e);
                    options.filterApplyCallback(e.value)
                }
                }
                optionLabel="label"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
            />

            // <Select placeholder='Select Service' options={inquiryServicesList} value={inquiryServiceName} onChange={e => {
            //     setInquiryServiceName(e)
            //     setInquiryServicesId(e.value)
            //     onChangeServiceId(e)
            // }} className="mt-1 my-react-select-container"
            //     classNamePrefix="my-react-select" />
        );
    };


    //filter for requester
    const requester = [];
    requestorList.map((r) => {
        requester.push(r.label);
    });

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const statusRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={requester} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    //origin port filter
  
    const ports = [];
    portList.map((r) => {
        ports.push(r.label);
    });
    const originPortItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const originPortRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={ports} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={originPortItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    //cargo type filter
    const cargoType = [];
    portList.map((r) => {
        ports.push(r.label);
    });
    const cargoTypeTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const cargoTypeRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={ports} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={cargoTypeTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }


    useEffect(() => {
        onLoad();
        // FilterData()
        BindList();
        FiltersData();
        loadLazyData();
        initFilters1(inquirySearchTxt);
    }, [pageNo, lazyParams]);


    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            getCustomers({ lazyEvent: JSON.stringify(lazyParams) }).then(async data => {
                console.log("MY", data.inquiries[1]);
                let paginatedInquiries = [];
                for(let i = 0; i<data.inquiries.length; i++)
                {

                    // Inquiry, InquiryStatus, InquirySource, InquiryServices, Customer, SalesPerson, InquiryCargoType, Country, InquiryPort, InquiryBid

                    const serviceDetails = await DataStore.query(InquiryServices, data.inquiries[i].inquiryServiceId);
                    const requesterDetails = await DataStore.query(Customer, data.inquiries[i].customerId);
                    const originPortDetails = await DataStore.query(InquiryPort, data.inquiries[i].inquiryOriginPort);
                    const destinationPortDetails = await DataStore.query(InquiryPort, data.inquiries[i].inquiryDestinationPort);
                    const inquiryCargoTypeDetails = await DataStore.query(InquiryCargoType, data.inquiries[i].inquiryCargoTypeId);

                    let dataPush = {
                        "inquiryNo": data.inquiries[i].inquiryNo,
                        "inquiryDate": data.inquiries[i].inquiryDate === "1970-01-01T12:30:23.999Z" ? "" : moment(data.inquiries[i].inquiryDate).format('MM/DD/YYYY'),
                        "inquiryServiceId": serviceDetails ? serviceDetails.name : '',
                        "inquirySource": data.inquiries[i].inquirySource,
                        "customerId": requesterDetails ? requesterDetails.firstName+' '+requesterDetails.lastName : '',
                        "inquiryOriginPort": originPortDetails ? originPortDetails.name : '',
                        "inquiryDestinationPort": destinationPortDetails ? destinationPortDetails.name : '',
                        "inquiryCargoTypeId": inquiryCargoTypeDetails ? inquiryCargoTypeDetails.name : '',
                        "inquiryCargoReadyDate": data.inquiries[i].inquiryCargoReadyDate === "1970-01-01T12:30:23.999Z" ? "" : moment(data.inquiries[i].inquiryCargoReadyDate).format('MM/DD/YYYY'),
                        "contractDuration": '1 Month',
                        "inquiryClosingDate": data.inquiries[i].inquiryClosingDate === "1970-01-01T12:30:23.999Z" ? "" : moment(data.inquiries[i].inquiryClosingDate).format('MM/DD/YYYY'),
                        "bidsInvited": '-',
                        "bidsReceived": '-',
                        "quoteSubmittedDate": '-',
                        "customerResponseDate": '-',
                        "status": '-',
                        "inquiryCycleTime": '-',
                    }
                    paginatedInquiries.push(dataPush)
                }
                console.log("paginatedInquiries", paginatedInquiries);
                setTotalRecords(data.totalInquiries);
                setCustomers(paginatedInquiries);
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedCustomers(value);
        setSelectAll(value.length === totalRecords);
    }

    // const onSelectAllChange = (event) => {
    //     const selectAll = event.checked;

    //     if (selectAll) {
    //         getCustomers().then(data => {
    //             setSelectAll(true);
    //             setSelectedCustomers(data.customers);
    //         });
    //     }
    //     else {
    //         setSelectAll(false);
    //         setSelectedCustomers([]);
    //     }
    // }


    const serviceBodyTemplate = (rowData) => {

        let serviceTitle = getServiceDetails(rowData)

        return (
            <React.Fragment>
                <span className="vertical-align-middle ml-2">{serviceTitle}</span>
            </React.Fragment>
        );
    }

    const getServiceDetails = async(rowData) =>{
        let serviceTitle = '';
        if(rowData.inquiryServiceId)
        {
            let serviceDetails = await DataStore.query(InquiryServices, rowData.inquiryServiceId);
            if(serviceDetails)
                serviceTitle = serviceDetails.name;
        }

        console.log("serviceTitle",serviceTitle)
        debugger;
        return serviceTitle;
    }
    const header1 = renderHeader1();

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                <div className="p-5 justify-between pb-10">
                    <div className="sm:flex justify-between  items-center space-x-8 mb-5">
                        <div className="text-2xl font-semibold dark:text-white">Inquiry</div>

                        <div className="justify-end">

                            <div className="mt-5 lg:mt-0">
                                <Link onClick={() => {
                                    AddInquiry()
                                    reactLocalStorage.set('isInquiryEdit', false);
                                }}
                                    className="bg-[#029046] text-white rounded-md px-4 py-3 lg:py-2 text-[16px] lg:text-md inline-block addclassName" ><i className='pi pi-plus font-bold mr-2'></i> Add New Inquiry</Link>
                            </div>
                        </div>


                    </div>
                    <div className='flex justify-between dark:text-white'> Total: {totalInquiryCount} Requests</div>

                    {/* <div className='mt-10 flex-row grid 2xl:grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-1  gap-3 mb-10'> */}

                    <div className="bg-white rounded-lg mt-2 p-3 px-3 dark:bg-[#2A2E32] mt-5  border border-[#E4E7EC] dark:border-[#344054] dark:text-white  pb-10 ">


                        {/* <Link to='' className='flex justify-center flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-download'></i> Import</Link> */}

                        <div className='grid 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1  gap-3 filtter mb-3'>
                            {/* <input type="text" value={sampleText}/>  */}
                            <Calendar dateFormat="dd/mm/yy" className='inputHieght' maxDate={new Date()} value={fromdateOfEnquiry} onChange={(e) => {
                                setFromDateOfEnquiry(e.value)
                                onChangeFromDateInquiry(e)
                            }} readOnlyInput />

                            <Calendar dateFormat="dd/mm/yy" className='inputHieght' maxDate={new Date()} value={todateOfEnquiry} onChange={(e) => {
                                setToDateOfEnquiry(e.value)
                                onChangeToDateInquiry(e)
                            }} readOnlyInput />

                            <Select placeholder='Requestor' options={requestorList}
                                isClearable={true}
                                //  value={requestorList.filter(obj => requestorId.includes(obj.value))}
                                value={requestorName}
                                onChange={e => {
                                    setRequestorName(e)
                                    setRequestorId(e.value)
                                    // setRequestorId(Array.isArray(e) ? e.map(x => x.value) : [])
                                    onChangeRequestor(e)

                                }}
                                className="mt-1 my-react-select-container"
                                classNamePrefix="my-react-select" />

                            <Select placeholder='Sales Person' options={salesPersonList} value={salesPersonName}
                                onChange={e => {
                                    setSalesPersonName(e)
                                    setSalesPersonId(e.value)
                                    onChangeSalePerson(e)
                                }}
                                className="mt-1 my-react-select-container"
                                classNamePrefix="my-react-select" />
                            <Select placeholder='Select Service' options={inquiryServicesList} value={inquiryServiceName} onChange={e => {
                                setInquiryServiceName(e)
                                setInquiryServicesId(e.value)
                                onChangeServiceId(e)
                            }} className="mt-1 my-react-select-container"
                                classNamePrefix="my-react-select" />
                            <div className='col-span-2'>
                                <Select placeholder='Cargo Type' options={cargoTypeList} value={cargoTypeName} onChange={e => {
                                    setCargoTypeName(e)
                                    setCargoTypeId(e.value)
                                    onChangeCargoId(e)
                                }} className="mt-1 my-react-select-container"
                                    classNamePrefix="my-react-select" />
                            </div>

                            <button
                                onClick={() => getFilter()} className="bg-[#029046] text-white rounded-md px-4 py-3 lg:py-2 text-[16px] lg:text-md inline-block addclassName h-11 mt-1 justify-center  items-center" >Go</button>

                        </div>


                        <div>
            <div className="card">
                <DataTable value={customers} lazy responsiveLayout="scroll" dataKey="id"
                    paginator first={lazyParams.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                    onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                    selection={selectedCustomers} onSelectionChange={onSelectionChange}>
                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                    {/* <Column field="country.name" sortable header="Country" filterField="country.name" body={countryBodyTemplate} filterPlaceholder="Search by country" /> */}
                    <Column field="inquiryNo" header="Inquiry Id" sortable />
                    <Column field="inquiryDate" header="Inquiry Date" sortable />
                    <Column field="inquiryServiceId" header="Service" sortable />
                    <Column field="inquirySource" header="Inquiry Source" sortable />
                    <Column field="customerId" header="Requestor" sortable />
                    <Column field="inquiryOriginPort" header="Origin Port" sortable />
                    <Column field="inquiryDestinationPort" header="Destination Port" sortable />
                    <Column field="inquiryCargoTypeId" header="Cargo Type" sortable />
                    <Column field="inquiryCargoReadyDate" header="Cargo Ready Date" sortable />
                    <Column field="contractDuration" header="Contract Duration" sortable />
                    <Column field="inquiryClosingDate" header="Inquiry Closing Date" sortable />
                    <Column field="bidsInvited" header="Bids Invited" sortable />
                    <Column field="bidsReceived" header="Bids Received" sortable />
                    <Column field="quoteSubmittedDate" header="Quote Submitetted Date" sortable />
                    <Column field="customerResponseDate" header="Customer Response Date" sortable />
                    <Column field="status" header="Status" sortable />
                    <Column field="inquiryCycleTime" header="Inquiry Cycle Time" sortable />
                </DataTable>
            </div>
        </div>


                    </div>

                    {/* </div> */}
                </div>
            </div>

            <div id="selector"></div>


        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}