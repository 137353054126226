import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import Bussiness from "../../../../../assets/svg/connectpro/business-people-casual-meeting 1.png";
import { Editor } from 'primereact/editor';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Inquiry } from '../../../../../models';
import { toast } from 'react-toastify';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { callMsGraph, loginRequest, graphConfig } from "../../../../../authConfig";
import mic_icon from '../../../../../assets/svg/connectpro/mic_icon.svg';
 
export const MailBoxComponent = () => {

    //Validate User Logged In
    const isMicrosoftAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [profilePic, setProfilePic] = useState("");
    const [isComposePopUp, setViewPopUp] = useState(0);
    const [isReplyToPopUp, setReplyToPopup] = useState(0);
    const [isInquiryToPopUp, setInquiryToPopup] = useState(0);
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [Description, setDescription] = useState("");



    const doAuth = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }

    const doAuthLogout = () => {
        instance.logoutRedirect({
            // postLogoutRedirectUri: 'http://localhost:3000/employee/mailBox',
            // postLogoutRedirectUri: 'https://www.proconnect.edbrix.info/employee/mailBox/logout',

            postLogoutRedirectUri: 'http://localhost:3000/employee/mailBox',
            // postLogoutRedirectUri: 'https://www.proconnect.edbrix.info/employee/mailBox/logout',
        });
    }

    //Set Inquiry
    const [selectedInquiries, setSelectedInquires] = useState([]);
    const [existingLinkedInquires, setExistingLinkedInquires] = useState([]);
    const [inquiryList, setInquiryList] = useState([]);


    const checkExistingInquiresLinkedWithMessage = async (messageId) => {
        setExistingLinkedInquires([])

        //Here we are going to get linked inquiry for this messages
        if (messageId !== '') {
            var linkedInquires = await DataStore.query(Inquiry, (c) => c.emailMessageId.eq(messageId))
            let existingLinkedInquires = []
            if (linkedInquires.length > 0) {
                for (let i = 0; i < linkedInquires.length; i++) {
                    existingLinkedInquires.push(linkedInquires[i].inquiryNo)
                }
                setExistingLinkedInquires(existingLinkedInquires)
                console.log(existingLinkedInquires);
            }
        }

    }

    const saveMapInquiry = async () => {

        if (selectedInquiries.length === 0) {
            toast.error("Please select inquiry.")
            return;
        }

        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

        for (var i = 0; i < selectedInquiries.length; i++) {

            const original = await DataStore.query(Inquiry, selectedInquiries[i].InquiryID);
            await DataStore.save(
                Inquiry.copyOf(original, (updated) => {
                    updated.emailMessageId = currentMessageId;
                    updated.emailMessageUserId = loggedEmployeeId;
                })
            );

        }

        await checkExistingInquiresLinkedWithMessage(currentMessageId);
        setSelectedInquires([]);
        setInquiryToPopup(0);

        toast.success("Inquiry mapped successfully.")

    }

    const pullInquiry = async () => {
        var inquiryResponse = await DataStore.query(Inquiry, Predicates.ALL, {});
        let inquiryFinalData = []
        for (let i = 0; i < inquiryResponse.length; i++) {
            let data = {
                "InquiryID": inquiryResponse[i].id,
                "InquiryNo": inquiryResponse[i].inquiryNo,
                "Inquiry Date": inquiryResponse[i].inquiryDate === "1970-01-01T12:30:23.999Z" ? "" : moment(inquiryResponse[i].inquiryDate).format('MM/DD/YYYY'),
                "Status": inquiryResponse[i].inquiryStatus,
                "Inquiry Closing Date": inquiryResponse[i].inquiryClosingDate === "1970-01-01Z" ? "" : moment(inquiryResponse[i].inquiryClosingDate).format('MM/DD/YYYY'),
            }
            inquiryFinalData.push(data);
        }
        setInquiryList(inquiryFinalData)
    }

    //Send Reply
    const [replyToText, setReplyToText] = useState("");
    async function SendReplyMessage() {

        var customQueryURL = graphConfig.graphMeSingleMessages + "/" + currentMessageId + '/replyAll';
        var requestBody = { comment: replyToText };
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken, customQueryURL, "POST", requestBody).then(async (currentResponse) => {
                if (currentResponse != null) {
                    await LoadMessageList();
                }
                setReplyToPopup(0);
            });
        });
    }


    //Get Conversation
    function RequestMessageConversationData(conversationId) {

        var customQueryURL = graphConfig.graphMeSingleMessages + "?$top=1&filter=conversationId eq '" + conversationId + "'";

        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken, customQueryURL).then(currentResponse => {
                if (currentResponse != null) {
                    var sentDateTime = moment(currentResponse.value[0].sentDateTime).format('dddd') + ', ' + moment(currentResponse.value[0].sentDateTime).format('MMMM DD, YYYY h:mm A');
                    setFirstEmailThreadDate(sentDateTime)
                }

            });
        });
    }

    //Load Messages List
    const [graphMessageList, setGraphMessageList] = useState([]);
    const [isMessagesListLoaded, setMessagesListLoaded] = useState(false);
    const [messageTypeToLoad, setMessageTypeToLoad] = useState("I");
    const [messageSearchExpression, setMessageSearchExpression] = useState("");

    async function LoadMessageList(currentTypeToLoad = '') {

        var requestURL = graphConfig.graphMeInboxMessages;;
        if (currentTypeToLoad !== '') {
            setMessageTypeToLoad(currentTypeToLoad);
            if (currentTypeToLoad === "A") { requestURL = graphConfig.graphMeAllMessages; }
            else if (currentTypeToLoad === "I") { requestURL = graphConfig.graphMeInboxMessages; }
            else if (currentTypeToLoad === "S") { requestURL = graphConfig.graphMeSendItemsMessages; }
        }
        else if (messageTypeToLoad !== "") {
            if (messageTypeToLoad === "A") { requestURL = graphConfig.graphMeAllMessages; }
            else if (messageTypeToLoad === "I") { requestURL = graphConfig.graphMeInboxMessages; }
            else if (messageTypeToLoad === "S") { requestURL = graphConfig.graphMeSendItemsMessages; }
        }

        if (messageSearchExpression !== '') {
            requestURL = requestURL + '&search="' + messageSearchExpression + '"';
        }

        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken, requestURL).then(currentResponse => {
                if (currentResponse != null) {

                    var mailingList = [];
                    currentResponse.value.forEach(async (currentMail) => {
                        var currentItem = [];
                        currentItem['messageId'] = currentMail.id;
                        currentItem['messageDateTime'] = moment(currentMail.createdDateTime).format('dddd').substring(0, 3) + ' ' + moment(currentMail.createdDateTime).format('L HH:mm');
                        currentItem['messageSubject'] = currentMail.subject;
                        currentItem['messagePreview'] = currentMail.bodyPreview.substring(0, 50);

                        currentItem['messageHasAttachments'] = currentMail.hasAttachments
                        currentItem['messageFromEmail'] = currentMail.from.emailAddress.address;
                        currentItem['messageFromName'] = currentMail.from.emailAddress.name;

                        mailingList.push(currentItem);
                    });
                    setGraphMessageList(mailingList);
                    setMessagesListLoaded(true);
                }
                else {
                    setGraphMessageList([])
                    setMessagesListLoaded(true);
                }

            });
        });
    }


    //Single Message Load
    const [graphSingleMessage, setGraphSingleMessage] = useState([]);
    const [currentMessageId, setCurrentMessageId] = useState('');
    const [firstEmailThreadDate, setFirstEmailThreadDate] = useState('');
    const [isSingleMessageLoaded, setSingleMessageLoaded] = useState(false);

    function RequestMessageData(messageId) {
        setFirstEmailThreadDate('');
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken, graphConfig.graphMeSingleMessages + '/' + messageId).then(async (currentMail) => {
                if (currentMail != null) {


                    var currentItem = [];
                    currentItem['messageId'] = currentMail.id;
                    // currentItem['messageDateTime'] = moment(currentMail.createdDateTime).format('dddd').substring(0, 3) + ' ' + moment(currentMail.createdDateTime).format('L HH:mm');


                    currentItem['messageDateTime'] = moment(currentMail.createdDateTime).format('llll') + ' (' + (moment(currentMail.createdDateTime).calendar().split(" at"))[0] + ')';

                    currentItem['messageSubject'] = currentMail.subject;
                    currentItem['messageBody'] = currentMail.body.content;
                    currentItem['messagePreview'] = currentMail.bodyPreview.substring(0, 100);
                    currentItem['messageIsRead'] = currentMail.isRead
                    currentItem['messageHasAttachments'] = currentMail.hasAttachments;
                    currentItem['messageWebLink'] = currentMail.webLink
                    currentItem['messageConversationId'] = currentMail.conversationId;

                    currentItem['messageFromEmail'] = currentMail.from.emailAddress.address;
                    currentItem['messageFromName'] = currentMail.from.emailAddress.name;

                    currentItem['messageToRecipients'] = currentMail.toRecipients;
                    currentItem['messageCCRecipients'] = currentMail.ccRecipients;
                    currentItem['messageBCCRecipients'] = currentMail.bccRecipients;
                    currentItem['messageReplyTo'] = currentMail.replyTo;

                    setGraphSingleMessage(currentItem);
                    setCurrentMessageId(currentMail.id)
                    setSingleMessageLoaded(true);

                    var bodyContent = currentMail.body.content;
                    let matches = bodyContent.match(/(?<=On\s+).*?(?=\s+&lt;)/gs);
                    if (matches != null && matches.length > 0) {
                        var currentMatch = matches[matches.length - 1];
                        setFirstEmailThreadDate(currentMatch);
                    }
                    else {
                        RequestMessageConversationData(currentMail.conversationId);
                    }

                    //Check Existing Messages
                    checkExistingInquiresLinkedWithMessage(messageId)

                }
                else {
                    setGraphSingleMessage([])
                    setSingleMessageLoaded(true);
                    setExistingLinkedInquires([])
                }

            });
        });
    }

    // Send mail
    async function sendMail() {
        sendEmailToFrightForward(email, subject, Description);
        toast.success("Email Send Successfully");
        setViewPopUp(0)
    }

    async function sendEmailToFrightForward(freightForwarderEmail, mailSubject, emailTemplate) {

        if (isMicrosoftAuthenticated) {
            var customQueryURL = graphConfig.graphMeSendMessages;
            var requestBody = {
                "message": {
                    "subject": mailSubject,
                    "body": {
                        "contentType": "html",
                        "content": emailTemplate
                    },
                    "toRecipients": [
                        {
                            "emailAddress": {
                                "address": freightForwarderEmail
                            }
                        }
                    ],
                }
            };
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                callMsGraph(response.accessToken, customQueryURL, "POST", requestBody).then(async (currentResponse) => {
                });
            });
        }
    }

    async function onLoad() {
        //Load Default Inbox
        if (isMicrosoftAuthenticated) {
            await LoadMessageList();
        }

        await pullInquiry();
    }



    useEffect(() => {
        onLoad();


    }, []);

    return (
        <div>

            <div className="pl-16 dashboard-main-wrapper chat-wrapper" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="items-center flex justify-between space-x-8 sm:flex">
                        <div className="text-2xl font-semibold dark:text-white">Mailbox</div>
                        <div className='float-right lg:mt-0 flex items-center gap-4'>
                            <div className="searchbox">
                                <div className="relative">
                                    <input type="text" id="required-search" value={messageSearchExpression} onChange={(e) => setMessageSearchExpression(e.target.value)} className="rounded-lg border border-[#D0D5DD] focus:border-transparent  py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff]" placeholder="Search" />
                                    <button type="button" onClick={async () => await LoadMessageList()} className="absolute left-3 top-1/2 translate-y-[-50%] ">
                                        <i className="icon search_icon "></i></button></div>
                            </div>

                            {isMicrosoftAuthenticated === true
                                ?
                                <div>
                                    <button onClick={async () => await LoadMessageList()} className="border bg-[#FFF]  py-1.5 px-5 h-10 text-[#029046] text-[15px] ml-2 rounded-md active addClass  dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]"><i className='pi pi-refresh'></i>  Refresh</button>
                                    <button onClick={() => doAuthLogout()} className="border bg-[#FFF]  py-1.5 px-5 h-10 text-[#029046] text-[15px] ml-2 rounded-md active addClass  dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]"><i className='pi pi-sign-out mr-1'></i> Logout</button>
                                    <button onClick={() => setViewPopUp(1)} className="border bg-[#029046] py-1.5 px-5 h-10 text-white text-[15px] ml-2 rounded-md active addClass dark:border-[#029046]"><i className='pi pi-plus'></i>  Compose</button>
                                </div>
                                :
                                <button onClick={() => doAuth()} className="border bg-[#029046] py-1.5 px-5 h-10 text-white text-[15px] rounded-md active addClass dark:border-[#029046]"><i className='pi pi-plus'></i>  Login</button>
                            }


                        </div>
                    </div>
                </div>
                <div className='px-5 pt-2 pb-0'>
                    <div className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-2 align-center 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1 setting-wrapper">

                        <div className="col-span-4">
                            <div className="p-2 px-3 bg-white border border-[#E4E7EC] rounded-lg dark:bg-[#2A2E32] dark:border-[#414548]">
                                <div className="mailbox_chat mt-5 sm:mt-0">
                                    <ul className="flex bg-[#E5F3EC] justify-between rounded-lg mb-3 px-3">
                                        <li><Link onClick={async () => { await LoadMessageList("A"); }} className={messageTypeToLoad === "A" ? 'active' : ''}>All</Link></li>
                                        <li><Link onClick={async () => { await LoadMessageList("I"); }} className={messageTypeToLoad === "I" ? 'active' : ''}>Inbox</Link></li>
                                        <li><Link onClick={async () => { await LoadMessageList("S"); }} className={messageTypeToLoad === "S" ? 'active' : ''}>Sent Items</Link></li>
                                    </ul>
                                    <div className=" chat-list scroll-smooth scroll-pr-px overflow-auto h-[30rem]  xl:h-[30rem] lg:h-[26rem] 2xl:[35rem]">
                                        {

                                            isMicrosoftAuthenticated === true && isMessagesListLoaded === true && graphMessageList.length > 0 && graphMessageList.map((item, i) =>

                                                <div key={'inboxMessageId_' + i} onClick={() => RequestMessageData(item.messageId)} className='cursor-pointer bg-[#FCFCFD] rounded-md shadow-chatbox p-3 mb-2 dark:bg-[#44494E] dark:text-[#FFFFFF]'>
                                                    <div className='flex flex-1 gap-2'>
                                                        <div className='relative w-16'>
                                                            <Avatar
                                                                src={profilePic} />
                                                        </div>
                                                        <div className='w-full'>
                                                            <div className='flex justify-between'>
                                                                <h3 className="chatname">{item.messageFromName}</h3>
                                                                {item.messageHasAttachments ? <i className='pi pi-paperclip clip ml-7 mt-2'></i> : ""}
                                                                <div className='time mt-1 mr-6'>{item.messageDateTime}</div>
                                                            </div>
                                                            <div className='float-right mr-6 mt-5 fav_icon'><i className='pi pi-star
'></i></div>
                                                            <span className='text-[14px] mr-4 font-medium text-[#344054] leading-3 dark:text-white'>{item.messageSubject}</span>
                                                            <div className='flex'>
                                                                <p className='text-[#98A2B3] text-[12px]'>{item.messagePreview}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-span-8">
                            {/* <div className="p-3 px-3 bg-white border border-[#E4E7EC] rounded-lg dark:bg-[#2A2E32] dark:border-[#414548] overflow-auto h-[30rem]  xl:h-[30rem] lg:h-[26rem] 2xl:[35rem] defaultbox align-middle">
                                <div className='flex gap-5 justify-center items-center'>
                                    <div><Avatar alt="" src={reactLocalStorage.get('loggedEmployeeProfilePic')} /></div>
                                    <div>
                                        <div className='text-[28px] text-[#344054] font-light'>Welcome!</div>
                                        <div className="font-normal text-lg dark:text-white">{reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName')}</div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="p-3 px-3 bg-white border border-[#E4E7EC] rounded-lg dark:bg-[#2A2E32] dark:border-[#414548]">

                                {
                                    isMicrosoftAuthenticated === true && isSingleMessageLoaded === true && graphSingleMessage != null
                                        ?
                                        <div>
                                            <div id={graphSingleMessage.messageId} className="chat-header">
                                                <div className='flex justify-between gap-5 items-center'>
                                                    <div className='flex gap-3'>
                                                        <Avatar
                                                            src={profilePic} />
                                                        <div> <h5>{graphSingleMessage.messageSubject}</h5>
                                                            <h6>{graphSingleMessage.messageFromName} ({graphSingleMessage.messageFromEmail})</h6>
                                                        </div>
                                                    </div>
                                                    <div className='back-section'>
                                                        <Link onClick={() => window.open(graphSingleMessage.messageWebLink)}  ><i className="pi pi-external-link mr-3"></i></Link>
                                                        <Link x-data="{ open: false }"> <i className="pi pi-star mr-4"></i></Link>
                                                        <Link x-data="{ open: false }"> <i className="pi pi-ellipsis-v"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-2 text-[14px] dark:text-white mt-2">
                                                <strong>To</strong>:
                                                {
                                                    graphSingleMessage.messageToRecipients.map((item, i) => {

                                                        if (i > 0) {
                                                            return (', ' + item.emailAddress.name + ' <' + item.emailAddress.address + '>');
                                                        }
                                                        else {
                                                            return (' ' + item.emailAddress.name + ' <' + item.emailAddress.address + '>');
                                                        }

                                                    })
                                                }
                                                {/* <span className='block text-sm font-normal font-light mail_time float-right'>{graphSingleMessage.messageDateTime}</span> */}
                                                {console.log("dataa :", graphSingleMessage.messageDateTime)}
                                                {console.log("dataaArray :", graphSingleMessage)}

                                                <span className='block text-sm font-normal font-light mail_time float-right'>{graphSingleMessage.messageDateTime}</span>
                                                {
                                                    graphSingleMessage.messageCCRecipients.length > 0 ? <div><strong>Cc</strong>:
                                                        {
                                                            graphSingleMessage.messageCCRecipients.map((item, i) => {
                                                                if (i > 0) {
                                                                    return (', ' + item.emailAddress.name + ' <' + item.emailAddress.address + '>');
                                                                }
                                                                else {
                                                                    return (' ' + item.emailAddress.name + ' <' + item.emailAddress.address + '>');
                                                                }

                                                            })
                                                        }
                                                    </div> : ""
                                                }


                                            </div>

                                            <div className="chat-body" >
                                                <div className="relative w-full p-2 overflow-y-auto h-[24rem]  xl:h-[20rem] lg:h-[20rem] 2xl:[29rem] my-5">
                                                    <div dangerouslySetInnerHTML={{ __html: graphSingleMessage.messageBody }} />
                                                </div>

                                                <div className="flex justify-between w-full p-2 gap-2 msg-send-section mt-8 pt-5">
                                                    <div>
                                                        <button onClick={() => setInquiryToPopup(1)} className="border bg-[#029046] py-1.5 px-5 h-10 text-white text-[15px] rounded-md active addClass dark:border-[#029046]">Link to Inquiry</button>
                                                        {
                                                            isSingleMessageLoaded === true ?
                                                                <div className='text-[#029046] text-[12px] mt-2'>{firstEmailThreadDate}</div>
                                                                : ""
                                                        }
                                                        {
                                                            isSingleMessageLoaded === true && existingLinkedInquires.length > 0 ?
                                                                <div className='text-[#029046] text-[12px] mt-2'>Linked inquires: {existingLinkedInquires.join(", ")}</div>
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className='flex gap-2'>
                                                        <button onClick={() => setReplyToPopup(1)} className="buttons_mailbox  bg-[White] py-1.5 px-5 h-10 text-[#4FB155] text-[15px] rounded-md active addClass dark:border-[#029046] "><i className='icon icoonreply'></i>Reply all</button>
                                                        <button className="buttons_mailbox bg-[White] py-1.5 px-5 h-10 text-[#4FB155] text-[15px] rounded-md active addClass dark:border-[#029046]"><i className='icon icoonforward'></i>Forward</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        : ""

                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="selector" className={isComposePopUp === 1 ? 'overlay' : ''}></div>

            {
                isComposePopUp === 1 ?
                    <div style={{
                        width: "50vw",
                        top: "8vw", animation: "popupDiv-slide-in 0.5s forwards"
                    }} className="sidenav h-full 2xl:h-[37vw] dashboard-main-wrapper rounded-md send_format">
                        <div className="absolute  flex justify-between  bg-[#E4E7EC] right-0 left-0 top-0 w-full text-white dark:bg-[#2A2E32] dark:border-[#414548] dark:text-white">

                            <span className='text-black mt-3 font-normal ml-3 text-sm dark:text-white'>New Mail</span>
                            <div className="flex  px-6 py-2 ">
                                <div><Link onClick={() => setViewPopUp(0)} className="closebtn text-2xl leading-none removeClass text-[#01813F]">&times;</Link></div>
                            </div>

                        </div>

                        <div className='p-3 mt-10'>
                            <div className='mb-2 '>
                                <span className='text-[#667085] text-base'>To</span>
                                <input type="text" onChange={(event) => setEmail(event.target.value)} className='border-b-2 focus:border-b  bg-white text-gray-700 placeholder-gray-500 text-md focus:outline-none dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff] w-full' />
                            </div>
                            <div>
                                <div className='flex items-center gap-2'>
                                    <span className='text-[#667085] text-base'>Subject</span>
                                    <a href='#'>
                                    <img src={mic_icon} height={'18px'} width={'18px'} alt=''/>
                                    </a>
                                </div>
                                <input type="text" onChange={(event) => setSubject(event.target.value)}  className='border-b-2 focus:border-b bg-white text-gray-700 placeholder-gray-500 text-md focus:outline-none dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff] w-full' />
                            </div>
                        </div>

                        <div className='flex items-center justify-end gap-2 mr-2'>
                            <a href='#' className='flex items-center gap-1 px-2 py-1 bg-blue-100 rounded-full'>
                                <img src={mic_icon} height={'16px'} width={'16px'} alt='' />
                                <span className='text-[#667085] text-[12px] font-medium'>Voice Transcription</span>
                            </a>
                        </div>

                        <div className='relative'>
                        <Editor onTextChange={(e) => setDescription(e.htmlValue)} style={{ height: '200px' }} />
                            <div className='absolute right-3 top-4'>
                            <select class="flex items-center bg-gray-200 rounded-full p-1 text-xs mr-2 language-select dark:text-[#AAAAAA] border border-transparent dark:border-[#AAAAAA] dark:bg-[#13161B]"><option>Translate to</option><option value="en">English</option><option value="es">Spanish</option><option value="fr">French</option><option value="de">German</option><option value="it">Italian</option><option value="pt">Portuguese</option><option value="ru">Russian</option><option value="zh-CN">Chinese (Simplified)</option><option value="ja">Japanese</option><option value="ko">Korean</option><option value="ar">Arabic</option><option value="hi">Hindi</option><option value="tr">Turkish</option><option value="nl">Dutch</option><option value="sv">Swedish</option><option value="fi">Finnish</option><option value="da">Danish</option><option value="no">Norwegian</option><option value="pl">Polish</option><option value="cs">Czech</option><option value="hu">Hungarian</option><option value="ro">Romanian</option><option value="bg">Bulgarian</option><option value="el">Greek</option><option value="he">Hebrew</option><option value="id">Indonesian</option><option value="ms">Malay</option><option value="th">Thai</option><option value="vi">Vietnamese</option><option value="af">Afrikaans</option><option value="sq">Albanian</option><option value="hy">Armenian</option><option value="az">Azerbaijani</option><option value="eu">Basque</option><option value="bs">Bosnian</option><option value="ca">Catalan</option><option value="hr">Croatian</option><option value="et">Estonian</option></select>
                            </div>
                        </div>
 

                        <div className="absolute left-0 right-0 bottom-0  bg-[#E5F3EC] p-3  dark:bg-[#071008]">
                            <div className="flex justify-end w-full p-2 gap-2 msg-send-section">
                                <button className="buttons_mailbox  bg-[White]  py-1.5 px-5 text-[#4FB155] text-[14px] rounded-md active addClass dark:border-[#029046] ">Clear</button>
                                <button onClick={() => sendMail()} className="buttons_mailbox border bg-[#029046] py-1.5 px-5  text-white text-[15px] ml-2 rounded-md active dark:border-[#029046]">Send</button>
                            </div>
                        </div>
                    </div> : null
            }


            {
                isReplyToPopUp === 1 ?
                    <div style={{
                        width: "50vw",
                        top: "8vw", animation: "popupDiv-slide-in 0.5s forwards"
                    }} className="sidenav dashboard-main-wrapper rounded-md send_format h-full 2xl:h-[37vw]">

                        <div className="absolute flex justify-between  bg-[#E4E7EC] right-0 left-0 top-0 w-full text-white">
                            <span className='text-black mt-3 font-normal ml-3 text-sm'>Reply To</span>
                            <div className="flex  px-6 py-2 ">
                                <div><Link onClick={() => setReplyToPopup(0)} className="closebtn text-2xl leading-none removeClass text-[#01813F]">&times;</Link></div>
                            </div>
                        </div>

                        <div className='p-3 mt-10'>
                            {
                                graphSingleMessage.messageToRecipients.map((item, i) => {

                                    if (i > 0) {
                                        return (', ' + item.emailAddress.name + ' <' + item.emailAddress.address + '>');
                                    }
                                    else {
                                        return (' ' + item.emailAddress.name + ' <' + item.emailAddress.address + '>');
                                    }

                                })
                            }
                        </div>

                        <div className='flex items-center justify-end gap-2 mr-2'>
                            <a href='#' className='flex items-center gap-1 px-2 py-1 bg-blue-100 rounded-full'>
                                <img src={mic_icon} height={'16px'} width={'16px'} alt='' />
                                <span className='text-[#667085] text-[12px] font-medium'>Voice Transcription</span>
                            </a>
                        </div>
                        
                        <div className='relative'>
                            <Editor style={{ height: '350px' }} placeholder="Reply type here" onTextChange={(e) => setReplyToText(e.htmlValue)} />
                            <div className='absolute right-3 top-4'>
                            <select class="flex items-center bg-gray-200 rounded-full p-1 text-xs mr-2 language-select dark:text-[#AAAAAA] border border-transparent dark:border-[#AAAAAA] dark:bg-[#13161B]"><option>Translate to</option><option value="en">English</option><option value="es">Spanish</option><option value="fr">French</option><option value="de">German</option><option value="it">Italian</option><option value="pt">Portuguese</option><option value="ru">Russian</option><option value="zh-CN">Chinese (Simplified)</option><option value="ja">Japanese</option><option value="ko">Korean</option><option value="ar">Arabic</option><option value="hi">Hindi</option><option value="tr">Turkish</option><option value="nl">Dutch</option><option value="sv">Swedish</option><option value="fi">Finnish</option><option value="da">Danish</option><option value="no">Norwegian</option><option value="pl">Polish</option><option value="cs">Czech</option><option value="hu">Hungarian</option><option value="ro">Romanian</option><option value="bg">Bulgarian</option><option value="el">Greek</option><option value="he">Hebrew</option><option value="id">Indonesian</option><option value="ms">Malay</option><option value="th">Thai</option><option value="vi">Vietnamese</option><option value="af">Afrikaans</option><option value="sq">Albanian</option><option value="hy">Armenian</option><option value="az">Azerbaijani</option><option value="eu">Basque</option><option value="bs">Bosnian</option><option value="ca">Catalan</option><option value="hr">Croatian</option><option value="et">Estonian</option></select>
                            </div>
                        </div>

                        <div className="absolute left-0 right-0 bottom-8 bg-[#E5F3EC] p-3 dark:bg-[#071008]">
                            <div className="flex justify-end w-full p-1 gap-2 msg-send-section">
                                <button onClick={() => SendReplyMessage()} className="buttons_mailbox border bg-[#029046] py-1.5 px-5  text-white text-[15px] ml-2 rounded-md active dark:border-[#029046]">Send</button>
                            </div>
                        </div>

                    </div> : null
            }

            {
                isInquiryToPopUp === 1 ?
                    <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                        <div className=""> <img src={Bussiness} alt="" className='w-full' /></div>
                        <div className="absolute right-0 left-0 top-0 w-full text-white">
                            <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                <div className="text-md"><h6 className='text-[white]'>Inquiry</h6></div>
                                <div><Link onClick={() => setInquiryToPopup(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                            </div>
                            <div className="px-6 mt-12">
                                <span>
                                    <div className="text-sm text-[white]">Choose</div>
                                    <div className="text-2xl">Select Inquiry
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className='height-custom'>
                            <div className="py-2 px-5">
                                <div className='flex w-full mt-2 mb-5'>
                                    <div className="relative w-full">
                                        <input type="text" id="" className="w-full rounded-lg border border-[#D0D5DD] focus:border-transparent w-250 py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-green-800 dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff]" placeholder="Search..." />
                                        <button type="submit" className="absolute left-3 top-4 translate-y-[-50%] dark "><i className="icon search_icon "></i></button>
                                    </div>
                                </div>
                                <div className=" rounded-lg mt-1">
                                    <DataTable paginator value={inquiryList} responsiveLayout="scroll"
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                        selection={selectedInquiries} onSelectionChange={e => setSelectedInquires(e.value)}
                                    >
                                        <Column selectionMode="multiple" selectionAriaLabel="name" headerStyle={{ width: '3em' }}></Column>
                                        <Column field="InquiryNo" header="Inquiry ID" filter></Column>
                                        <Column field="Inquiry Date" header="Inquiry Date" filter filterPlaceholder="Search by Inquiry Date"></Column>
                                        <Column field='Inquiry Closing Date' header="Inquiry Closing Date" filter ></Column>
                                        <Column field='Status' header="Status" filter ></Column>
                                    </DataTable>
                                </div>

                            </div>
                        </div>
                        <div className="lg:absolute  left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                            <div className="flex justify-end gap-3">
                                <div>
                                    <Link onClick={() =>
                                        setInquiryToPopup(0)} className="bg-white 
                                 dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                    >Cancel</Link>
                                </div>
                                <div>
                                    <Link to="" onClick={async () => await saveMapInquiry()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Save Mapping</Link>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </div>
    )
}
